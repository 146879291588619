import {
  IActionVehicles,
  IVehiclePhoto,
  IVehiclesBrand,
  IVehiclesModels,
  IVehiclesProps,
  IVehiclesStatistic,
  IVehiclesVins,
  VehiclesActionTypes,
} from "../../types";

interface IInitialStateVehicles {
  vehicles: IVehiclesProps[] | null;
  vehiclesRequest: boolean;
  vehiclesBrands: IVehiclesBrand | null;
  vehiclesBrandsRequest: boolean;
  vehiclesModels: IVehiclesModels | null;
  vehiclesVins: IVehiclesVins | null;
  vehiclesNoticed: IVehiclesProps[] | null;
  vehiclesNoticedRequest: boolean;
  vehicle: IVehiclesProps | null;
  vehicleRequest: boolean;
  vehiclePhotoBlob: IVehiclePhoto | null;
  vehiclePhotoRequest: boolean;
  vehiclesStatistic: IVehiclesStatistic[] | null;
  vehiclesStatisticRequest: boolean;
}

/**
 * Vehicles редьюсер
 */

const initialState: IInitialStateVehicles = {
  vehicles: null,
  vehiclesRequest: false,
  vehiclesBrands: null,
  vehiclesModels: null,
  vehiclesVins: null,
  vehiclesBrandsRequest: false,
  vehiclesNoticed: null,
  vehiclesNoticedRequest: false,
  vehicle: null,
  vehicleRequest: false,
  vehiclePhotoBlob: null,
  vehiclePhotoRequest: false,
  vehiclesStatistic: null,
  vehiclesStatisticRequest: false,
};

export const vehiclesReducer = (
  state = initialState,
  action: IActionVehicles
): IInitialStateVehicles => {
  switch (action.type) {
    case VehiclesActionTypes.GET_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: action.payload,
      };
    case VehiclesActionTypes.GET_VEHICLES_REQUEST:
      return {
        ...state,
        vehiclesRequest: action.payload,
      };
    case VehiclesActionTypes.GET_VEHICLES_BRAND_SUCCESS:
      return {
        ...state,
        vehiclesBrands: action.payload,
      };
    case VehiclesActionTypes.GET_VEHICLES_BRAND_REQUEST:
      return {
        ...state,
        vehiclesBrandsRequest: action.payload,
      };
    case VehiclesActionTypes.GET_VEHICLES_MODELS_SUCCESS:
      return {
        ...state,
        vehiclesModels: action.payload,
      };
    case VehiclesActionTypes.GET_VEHICLES_VINS_SUCCESS:
      return {
        ...state,
        vehiclesVins: action.payload,
      };
    case VehiclesActionTypes.SET_VEHICLES_NOTICED_SUCCESS:
      return {
        ...state,
        vehiclesNoticed: action.payload,
      };
    case VehiclesActionTypes.SET_VEHICLES_NOTICED_REQUEST:
      return {
        ...state,
        vehiclesNoticedRequest: action.payload,
      };
    case VehiclesActionTypes.SET_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicle: action.payload,
      };
    case VehiclesActionTypes.SET_VEHICLE_REQUEST:
      return {
        ...state,
        vehicleRequest: action.payload,
      };
    case VehiclesActionTypes.SET_VEHICLE_PHOTO_SUCCESS:
      return {
        ...state,
        vehiclePhotoBlob: action.payload,
      };
    case VehiclesActionTypes.SET_VEHICLE_PHOTO_REQUEST:
      return {
        ...state,
        vehiclePhotoRequest: action.payload,
      };
    case VehiclesActionTypes.SET_VEHICLES_STATISTIC_SUCCESS:
      return {
        ...state,
        vehiclesStatistic: action.payload,
      };
    case VehiclesActionTypes.SET_VEHICLES_STATISTIC_REQUEST:
      return {
        ...state,
        vehiclesStatisticRequest: action.payload,
      };
    default:
      return state;
  }
};
