import {
  IActionSettings,
  IDemoSettings,
  SettingsActionTypes,
} from "../../types";

interface IInitialState {
  settingDemo: IDemoSettings[] | null;
  settingDemoRequest: boolean;
  settingsAddDemoRequest: boolean;
}

const initialState: IInitialState = {
  settingDemo: null,
  settingDemoRequest: false,
  settingsAddDemoRequest: false,
};

/**
 * Settings редьюсер
 */

export const settingsReducer = (
  state: IInitialState = initialState,
  action: IActionSettings
): IInitialState => {
  switch (action.type) {
    case SettingsActionTypes.SET_DEMO_SETTINGS_SUCCESS:
      return {
        ...state,
        settingDemo: action.payload,
      };
    case SettingsActionTypes.SET_DEMO_SETTINGS_REQUEST:
      return {
        ...state,
        settingDemoRequest: action.payload,
      };
    case SettingsActionTypes.SET_ADD_DEMO_SETTINGS_REQUEST:
      return {
        ...state,
        settingsAddDemoRequest: action.payload,
      };
    default:
      return initialState;
  }
};
