/**
 * Функция для получения стилей оформления иконок меню в сайтбаре
 */
import { IGeneratingAutocompleteObjectArray } from "../../types";

export const getIconStyle = (
  isDark: boolean | undefined
): {
  orange: string;
  white: string;
} =>
  isDark
    ? { orange: "#FF9800", white: "#fff" }
    : { orange: "#898989", white: "#454443" };

/**
 * Функция для генерации масива с объектами полей, для выпадающих списков
 */

export interface IGenerationDataForSelect {
  value: string;
  label: string;
}

export const generationDataForSelect = (
  array: string[]
): IGenerationDataForSelect[] => {
  return array.map((item: string) => ({
    value: item,
    label: item,
  }));
};

/**
 * Функция для генерации масива с объектами полей, для выпадающих списков
 * c мульти выбором
 */

export const generatingAutocompleteObjectArray = (
  fieldName: string[] | null
): IGeneratingAutocompleteObjectArray[] => {
  return fieldName ? fieldName.map((brand) => ({ title: brand })) : [];
};
