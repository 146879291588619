import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { TargetVehicle, VehicleNetworkMap } from "../../components";
import { IAlertProps } from "../../types";
import { useActionsHook, useTypedSelectorHook } from "../../utils";
import { EventID } from "./fragments";

/**
 * Страница выбранного события Журнала (Journal Event ID) *
 */

export const AlertPage: React.FC = () => {
  const { id } = useParams();
  const { getAlertActions, getVehicleActions, getVehiclePhotoAction } =
    useActionsHook();
  const { alert } = useTypedSelectorHook(({ alerts }) => alerts);
  const { vehicle, vehiclePhotoBlob } = useTypedSelectorHook(
    ({ vehicles }) => vehicles
  );
  const [vehiclePhoto, setVehiclePhoto] = useState<string | null>(null);
  const [alertData, setAlertData] = useState<IAlertProps | null>(null);

  useEffect(() => {
    if (id) {
      getAlertActions(id);
    }
  }, []);

  useEffect(() => {
    if (alert) {
      getVehicleActions(alert.vehicleId);
      getVehiclePhotoAction(alert.vehicleId);
      setAlertData(alert);
    }
  }, [alert]);

  useEffect(() => {
    if (vehiclePhotoBlob) {
      setVehiclePhoto(vehiclePhotoBlob.img);
    }
  }, [vehiclePhotoBlob]);

  return (
    <Container maxWidth={false}>
      <Grid item m="10px 0 20px">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/journal">
            Journals
          </Link>
          <Typography color="textPrimary">{id}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid sx={{ display: "flex", mb: "20px" }}>
        <TargetVehicle vehicleData={vehicle} vehicleImage={vehiclePhoto} />
        <EventID alertData={alertData} />
      </Grid>
      <Grid item mb="20px">
        <VehicleNetworkMap />
      </Grid>
    </Container>
  );
};
