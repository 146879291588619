import { Box, List, Stack } from "@mui/material";
import { styled } from "@mui/styles";

export const options = ["All", "Checked", "Unchecked"];

export const CustomizedStack = styled(Stack)({
  width: "280px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "9px 5px 9px 15px",
  backgroundColor: "rgba(255, 255, 255, 0.01)",
  boxShadow: "inset 0px -2px 0px #3A3838",
});

export const CustomizedBox = styled(Box)({
  height: "194px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(21, 21, 21, 0.7)",

  "& img": {
    width: 270,
    height: 124,
    objectFit: "contain",
  },
});

export const CustomizedList = styled(List)({
  backgroundColor: "#18181880",
  padding: 0,

  "& li": {
    display: "flex",
    alignItems: "center",
    boxShadow: "inset 0px -1px 0px rgba(224, 224, 224, 0.1)",
    padding: "4px",

    "& span:first-child": {
      width: 105,
      marginRight: "15px",

      "& div": {
        width: "100px",
        padding: "4px 10px",

        "& .MuiAlert-icon": {
          width: 35,
          marginRight: 0,
          padding: "4px 0",
        },
      },
    },
  },

  "& li:last-child": {
    boxShadow: "unset",
    "& span:first-child": {
      "& div": {
        width: "103px",
        padding: "4px 5px 4px 10px",

        "& .MuiAlert-icon": {
          width: 30,
          marginRight: 0,
          padding: "4px 0",
        },
      },
    },
  },

  "& li:first-child, & li:nth-child(2), & li:nth-child(3)": {
    padding: "5px 15px",
    boxShadow: "inset 0px -1px 0px rgba(224, 224, 224, 0.5)",
  },

  "& li:nth-child(2), & li:nth-child(3)": {
    padding: "5px 15px",
    boxShadow: "inset 0px -1px 0px rgba(224, 224, 224, 0.1)",
  },

  "& h5": {
    width: 60,
    marginLeft: 10,
  },
});
