import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import { ImpactRate } from "../../../components";
import {
  cities,
  continents,
  CustomizedBox,
  labels,
  rates,
} from "../../../constants";

// Todo Yusuf: описание
export const AppSettingsPage: React.FC = () => {
  return (
    <Grid item p="20px">
      <CustomizedBox>
        <Typography variant="h5">Time settings</Typography>
        <Stack flexDirection="row" m="20px 0">
          <Typography variant="body1" color="textSecondary" mr="20px">
            Event data:
          </Typography>
          <Typography variant="body1">16:20:32</Typography>
        </Stack>
        <Stack flexDirection="row" alignItems="center" mb="180px">
          <Typography variant="body1" color="textSecondary" mr="20px">
            Time zone:
          </Typography>
          <Stack flexDirection="row">
            <Autocomplete
              disablePortal
              options={continents}
              size="small"
              sx={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} label="Continent" />
              )}
            />
            <Autocomplete
              disablePortal
              options={cities}
              size="small"
              sx={{ width: 200, ml: "20px" }}
              renderInput={(params) => <TextField {...params} label="City" />}
            />
          </Stack>
        </Stack>
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label="Use the system time settings"
        />
      </CustomizedBox>
      <Grid p="40px 0">
        <Typography variant="h5">Notification settings</Typography>
        <FormControl component="fieldset">
          {labels.map((label) => (
            <Box>
              <Typography variant="body1" color="textSecondary" m="20px 0 10px">
                {label}
              </Typography>
              <Stack flexDirection="row" alignItems="center">
                {rates.map((rate) => (
                  <FormControlLabel
                    value={rate}
                    control={<Checkbox />}
                    label={<ImpactRate type={rate} />}
                  />
                ))}
              </Stack>
            </Box>
          ))}
        </FormControl>
      </Grid>
      <Box m="30px 0">
        <Button
          variant="contained"
          size="large"
          sx={{ width: "160px", marginRight: "20px" }}
        >
          Save
        </Button>
        <Button variant="outlined" size="large" sx={{ width: "160px" }}>
          Cancel
        </Button>
      </Box>
    </Grid>
  );
};
