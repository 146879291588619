import React from "react";

import vehicle from "../../../assets/images/vehicles.svg";
import { TitleBar } from "../TitleBar";
import { CustomizedGrid, CustomizedImageWrap } from "./_styled.constants";

/**
 * Карта машины и схема взаимодействия с ней Vehicle network map - статический JSX.Element *
 */

export const VehicleNetworkMap: React.FC = () => {
  return (
    <CustomizedGrid item>
      <TitleBar title="Vehicle network map" />
      <CustomizedImageWrap>
        <img src={vehicle} alt="Vehicle" />
      </CustomizedImageWrap>
    </CustomizedGrid>
  );
};
