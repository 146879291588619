import { Box, Button, styled, Typography } from "@mui/material";

export const Content = styled(Box)(
  () => `
  min-height: 100vh;
  max-width: 466px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
);

export const Title = styled(Typography)(
  () => `
  line-height: 180px;
  font-size: 240px;
`
);

export const SubTitle = styled(Typography)(
  () => `
  font-size: 38px;
  margin-top: 40px;
`
);

export const Description = styled(Typography)(
  () => `
  font-size: 20px;
  line-height: 23px;
  opacity: 0.7;
  font-weight: 300;
  margin-bottom: 40px;
`
);

export const CustomButton = styled(Button)(
  () => `
  back
`
);
