import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";

export const Trigger = () => {
  return (
    <Box sx={{ width: 620 }}>
      <Typography variant="h5" sx={{ color: "#898989" }}>
        Trigger
      </Typography>
      <Stack flexDirection="row" alignItems="center" m="20px 0 12px">
        <StyledTrigger disabled>Single</StyledTrigger>
        <StyledTrigger disabled>Multi</StyledTrigger>
      </Stack>
      <Typography variant="body1" color="textSecondary">
        Specify the time period for which the triggers will be counted, and
        their number
      </Typography>
      <Box m="20px 0">
        <Typography variant="h6" sx={{ color: "#898989" }}>
          Detection period
        </Typography>
        <Stack mt="15px" direction="row" spacing={2}>
          <Chip disabled label="30 min" color="primary" size="medium" />
          <Chip disabled label="1 hours" variant="outlined" />
          <Chip disabled label="2 hours" variant="outlined" />
          <Chip disabled label="3 hours" variant="outlined" />
          <Chip disabled label="4 hours" variant="outlined" />
          <Chip disabled label="5 hours" variant="outlined" />
        </Stack>
      </Box>
      <Box>
        <Typography variant="h6" mb="15px" sx={{ color: "#898989" }}>
          Detection threshhold
        </Typography>
        <TextField
          disabled
          variant="outlined"
          label="Minimum count"
          fullWidth
        />
      </Box>
    </Box>
  );
};

// Todo Yusuf: вынести в файл со стилями
const StyledTrigger = styled(Button)({
  width: "132px",
  height: "30px",
  color: "#FF9800",
});

const StyledForm = styled(FormControlLabel)({
  marginBottom: "17px",
});
