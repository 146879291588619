import { Dispatch } from "redux";

import {
  ISetVehicle,
  ISetVehiclePhoto,
  ISetVehiclePhotoRequest,
  ISetVehicleRequest,
  ISetVehicles,
  ISetVehiclesBrand,
  ISetVehiclesModels,
  ISetVehiclesNoticed,
  ISetVehiclesNoticedRequest,
  ISetVehiclesRequest,
  ISetVehiclesStatistic,
  ISetVehiclesStatisticRequest,
  ISetVehiclesVINS,
  IVehiclePhoto,
  IVehiclesBrand,
  IVehiclesModels,
  IVehiclesProps,
  IVehiclesStatistic,
  IVehiclesVins,
  VehiclesActionTypes,
} from "../../../types";
import { interceptor } from "../../../utils";

/**
 * Задает значение списка автомобилей
 *
 * @param {IVehiclesProps[]} payload - список автомобилей.
 * @returns {ISetVehicles}
 */

const setVehicles = (payload: IVehiclesProps[]): ISetVehicles => ({
  type: VehiclesActionTypes.GET_VEHICLES_SUCCESS,
  payload,
});

/**
 * Задает состояние запроса списка автомобилей
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetVehiclesRequest}
 */

const setVehiclesRequest = (inRequest: boolean): ISetVehiclesRequest => ({
  type: VehiclesActionTypes.GET_VEHICLES_REQUEST,
  payload: inRequest,
});

/**
 *  Выполняет запрос на получение списка автомобилей
 */

export const getVehiclesActions = (filterData?: any) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setVehiclesRequest(true));
    try {
      const { status, data } = await interceptor.post("/vehicles/query", {
        ...filterData,
      });

      if (status === 200) {
        dispatch(setVehicles(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setVehiclesRequest(false));
  };
};

/**
 * Задает значение информации по автомобилю
 *
 * @param {IVehiclesProps} payload - список автомобилей.
 * @returns {ISetVehicle}
 */

const setVehicle = (payload: IVehiclesProps): ISetVehicle => ({
  type: VehiclesActionTypes.SET_VEHICLE_SUCCESS,
  payload,
});

/**
 * Задает состояние запроса по информации об автомобиле
 *
 * @param {boolean} isRequest - выполняется ли запрос.
 * @returns {ISetVehicleRequest}
 */

const setVehicleRequest = (isRequest: boolean): ISetVehicleRequest => ({
  type: VehiclesActionTypes.SET_VEHICLE_REQUEST,
  payload: isRequest,
});

/**
 *  Выполняет запрос на получение информации по автомобилю
 */

export const getVehicleActions = (vehicleId: string | number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setVehicleRequest(true));
    try {
      const { status, data } = await interceptor.get(
        `/vehicles/${vehicleId}`,
        {}
      );

      if (status === 200) {
        dispatch(setVehicle(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setVehicleRequest(false));
  };
};

/**
 * Задает значение списка бренда автомобилей
 *
 * @param {IVehiclesBrand[]} payload - список брендов автомобилей.
 * @returns {ISetVehiclesBrand}
 */

const setVehiclesBrand = (payload: IVehiclesBrand): ISetVehiclesBrand => ({
  type: VehiclesActionTypes.GET_VEHICLES_BRAND_SUCCESS,
  payload,
});

export const getVehiclesBrandAction = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const {
        status,
        data: { brands },
      } = await interceptor.get("/vehicles/list/brands");

      if (status === 200) {
        dispatch(setVehiclesBrand(brands));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Задает значение списка моделей автомобиля  сортированных по бренду
 *
 * @param {IVehiclesModels} payload - список моделей автомобилей
 * @returns {ISetVehiclesModels}
 */

const setVehiclesModels = (payload: IVehiclesModels): ISetVehiclesModels => ({
  type: VehiclesActionTypes.GET_VEHICLES_MODELS_SUCCESS,
  payload,
});

interface IBrands {
  brands: string[];
}

export const getModelsByBrandsAction = (brands: IBrands) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const {
        status,
        data: { vehicleModels },
      } = await interceptor.post("/vehicles/list/models", {
        ...brands,
      });

      if (status === 200) {
        dispatch(setVehiclesModels(vehicleModels));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Задает значение списка вин номеров сортированных по модели
 *
 * @param {IVehiclesVins} payload - список вин номеров
 * @returns {ISetVehiclesModels}
 */

const setVehiclesVins = (payload: IVehiclesVins): ISetVehiclesVINS => ({
  type: VehiclesActionTypes.GET_VEHICLES_VINS_SUCCESS,
  payload,
});

interface IModels {
  vehicleModels: IVehiclesModels;
}

export const getVinsByModels = (models: IModels) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const {
        status,
        data: { vins },
      } = await interceptor.post("/vehicles/list/vins", {
        ...models,
      });

      if (status === 200) {
        dispatch(setVehiclesVins(vins));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Задает состояние запроса списка noticed для автомобилей
 *
 * @param {boolean} isRequest - выполняется ли запрос.
 * @returns {ISetVehiclesRequest}
 */

const setVehiclesNoticedRequest = (
  isRequest: boolean
): ISetVehiclesNoticedRequest => ({
  type: VehiclesActionTypes.SET_VEHICLES_NOTICED_REQUEST,
  payload: isRequest,
});

/**
 * Задает значение списка noticed для автомобилей
 *
 * @param {IVehiclesProps[]} payload - выполняется ли запрос.
 * @returns {ISetVehiclesRequest}
 */

const setVehiclesNoticed = (
  payload: IVehiclesProps[]
): ISetVehiclesNoticed => ({
  type: VehiclesActionTypes.SET_VEHICLES_NOTICED_SUCCESS,
  payload: payload,
});

/**
 * Выполняет запрос на получение списка noticed для автомобилей
 */

export const getVehiclesNoticedAction = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setVehiclesNoticedRequest(true));
    try {
      const { status, data } = await interceptor.post("/vehicles/query", {
        data: { isNoticed: true },
      });

      if (status === 200) {
        // Так как с сервера не приходит отфильтрованный массив, здесь добавлена фильтрация
        dispatch(
          setVehiclesNoticed(
            data.filter((item: IVehiclesProps) => item.isNoticed)
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setVehiclesNoticedRequest(false));
  };
};

/**
 * Выполняет запрос на добавление автомобиля в Noticed
 *
 * @param {string} vehicleId - ID машины
 * @param {string} callback - Callback выполняется после успешного добаления в Noticed
 */

export const addVehicleNoticedAction = (
  vehicleId: string,
  callback: () => void
) => {
  return async (): Promise<void> => {
    try {
      const { status } = await interceptor.post(
        `/vehicles/${vehicleId}/notice`
      );

      if (status === 204) {
        callback();
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Задает значение информации по статистике автомобилей
 *
 * @param {IVehiclesStatistic} payload - список статистики.
 * @returns {ISetVehiclesStatistic}
 */

const setVehiclesStatistic = (
  payload: IVehiclesStatistic[]
): ISetVehiclesStatistic => ({
  type: VehiclesActionTypes.SET_VEHICLES_STATISTIC_SUCCESS,
  payload: payload,
});

/**
 * Задает состояние запроса на получение статистики по автомобилям
 * @param {boolean} isRequest - выполняется ли запрос.
 * @returns {ISetVehiclesStatisticRequest}
 */

const setVehiclesStatisticRequest = (
  isRequest: boolean
): ISetVehiclesStatisticRequest => ({
  type: VehiclesActionTypes.SET_VEHICLES_STATISTIC_REQUEST,
  payload: isRequest,
});

/**
 * Выполняет запрос на получение статистики по автомобилям
 */
export const getVehiclesStatistic = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setVehiclesStatisticRequest(true));
    try {
      const { status, data } = await interceptor.get(
        "vehicles/statistic/brands"
      );

      if (status === 200) {
        dispatch(setVehiclesStatistic(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setVehiclesStatisticRequest(false));
  };
};

/**
 * Задает значение информации по автомобилю
 *
 * @param {IVehiclesProps} payload - список автомобилей.
 * @returns {ISetVehicle}
 */

const setVehiclePhoto = (payload: IVehiclePhoto): ISetVehiclePhoto => ({
  type: VehiclesActionTypes.SET_VEHICLE_PHOTO_SUCCESS,
  payload,
});

/**
 * Задает состояние запроса по информации об автомобиле
 *
 * @param {boolean} isRequest - выполняется ли запрос.
 * @returns {ISetVehicleRequest}
 */

const setVehiclePhotoRequest = (
  isRequest: boolean
): ISetVehiclePhotoRequest => ({
  type: VehiclesActionTypes.SET_VEHICLE_PHOTO_REQUEST,
  payload: isRequest,
});

/**
 * Выполняет запрос на получение изображения автомобилям
 */

export const getVehiclePhotoAction = (vehicleId: string | number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setVehiclePhotoRequest(true));
    dispatch(setVehiclePhoto({ img: "" }));
    try {
      const { status, data } = await interceptor.get(
        `/vehicles/${vehicleId}/photo`,
        { responseType: "blob" }
      );

      if (status === 200) {
        dispatch(
          setVehiclePhoto({
            img: URL.createObjectURL(data),
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setVehiclePhotoRequest(false));
  };
};
