import { Box, Chip, Grid, List, StepLabel, Typography } from "@mui/material";
import { styled } from "@mui/styles";

/**
 * Стили страницы Detector (Detector page) *
 */

export const CustomizedBox = styled(Box)({
  maxWidth: 450,
  border: "1px solid #FF9800",
  borderRadius: "4px",
  padding: "10px",
  marginBottom: "24px",
});

export const CustomizedStepLabel = styled(StepLabel)({
  color: "#fff",
  fontWeight: 500,
});

export const CustomizedTypography = styled(Typography)({
  maxWidth: 750,
  margin: "12px 0 40px",
});

export const CustomizedGrid = styled(Grid)({
  paddingBottom: "30px",
  borderBottom: "1px solid rgba(133, 133, 133, 0.2)",
});

export const CustomizedList = styled(List)({
  display: "flex",
  justifyContent: "space-between",

  "& li": {
    display: "block",
    width: "25%",

    "& h6": {
      marginBottom: 32,
    },
  },
});

export const CustomizedChip = styled(Chip)({
  width: 40,
  height: 24,
  position: "relative",

  "&:before, &:after": {
    content: "''",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    width: "1px",
    height: "16px",
    backgroundColor: "#FFF",
  },
  "&:before": {
    top: -20,
  },
  "&:after": {
    bottom: -20,
  },

  "& span": {
    padding: "4px",
  },
});
