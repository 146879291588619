import React, { useEffect, useState } from "react";

import { PieChart } from "../../../../../../components/molecules/Charts";
import { IAlertsStatistic } from "../../../../../../types";
import { useActionsHook, useTypedSelectorHook } from "../../../../../../utils";

/**
 * Вкладка алертов за все время раздела Alerts Analytics - All *
 */

export const AlertsAll: React.FC = () => {
  const { alertsStatistic } = useTypedSelectorHook(({ alerts }) => alerts);
  const { getAlertsStatisticActions } = useActionsHook();
  const [pieChartAll, setPieChartAll] = useState<IAlertsStatistic[]>([]);

  useEffect(() => {
    getAlertsStatisticActions("all");
  }, []);

  useEffect(() => {
    if (alertsStatistic) {
      setPieChartAll(
        alertsStatistic.map((item) => {
          return {
            ...item,
          };
        })
      );
    }
  }, [alertsStatistic]);

  return (
    <PieChart
      id="dashboardPieChart"
      value="value"
      category="category"
      data={pieChartAll}
      width={200}
    />
  );
};
