import { PowerSettingsNew } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../../../../assets/images/logo.svg";
import { useActionsHook } from "../../../../../utils";

/**
 * Фрагмент верхней панели с датой, временем и логином
 *
 * @returns {React.FC}
 */

export const HeaderBar: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useActionsHook();

  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [time, setTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const secTimer = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
      setDate(new Date().toLocaleDateString());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  return (
    <CustomizedAppBar position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/">
            <img src={logo} alt="AutoVisor SIEM" />
          </Link>
        </Box>
        <Box sx={{ display: { md: "flex" } }}>
          <Stack spacing={4} direction="row" alignItems="center">
            <Typography variant="h5" component="div">
              {time}
            </Typography>
            <Typography variant="h5" component="div">
              {date}
            </Typography>
            <Button
              onClick={() => {
                logout(() => {
                  navigate("/login", { replace: true });
                });
              }}
              variant="text"
              startIcon={<PowerSettingsNew />}
              color="inherit"
              size="large"
              sx={{ fontSize: "18px" }}
            >
              Admin
            </Button>
          </Stack>
        </Box>
      </Toolbar>
    </CustomizedAppBar>
  );
};

const CustomizedAppBar = styled(AppBar)(
  () => `
  height: 64px;
  background-color: #0a0a0a;
  background-image: none;
  z-index: 1201;
  position: fixed;
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.1);
`
);
