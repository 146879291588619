import { Dispatch } from "redux";

import {
  IDemoSettings,
  ISetAddDemoSettingsRequest,
  ISetDemoSettings,
  ISetDemoSettingsRequest,
  SettingsActionTypes,
} from "../../../types";
import { interceptor } from "../../../utils";

/**
 * Задает значение полей Demo Settings
 *
 * @param {IDemoSettings[]} payload - список событии/Events.
 * @returns {ISetEvents}
 */

const setDemoSettings = (payload: IDemoSettings[]): ISetDemoSettings => ({
  type: SettingsActionTypes.SET_DEMO_SETTINGS_SUCCESS,
  payload,
});

/**
 * Задает состояние запроса полей Demo Settings
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetDetectorsRequest}
 */

const setDemoSettingsRequest = (
  inRequest: boolean
): ISetDemoSettingsRequest => ({
  type: SettingsActionTypes.SET_DEMO_SETTINGS_REQUEST,
  payload: inRequest,
});

/**
 *  Выполняет запрос на получение значений для полей Demo Settings.
 */

export const getDemoSettingsActions = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setDemoSettingsRequest(true));
    try {
      const { status, data } = await interceptor.get("/settings");

      if (status === 200) {
        dispatch(setDemoSettings(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setDemoSettingsRequest(false));
  };
};

/**
 * Задает состояние передачи полей Demo Settings
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetDetectorsRequest}
 */

const setAddDemoSettingsRequest = (
  inRequest: boolean
): ISetAddDemoSettingsRequest => ({
  type: SettingsActionTypes.SET_ADD_DEMO_SETTINGS_REQUEST,
  payload: inRequest,
});

/**
 *  Выполняет запрос-передачи значений для полей Demo Settings.
 */

export const setDemoSettingsActions = (
  alertDelay: string,
  eventDelay: string,
  timezone: string,
  isSimulation: boolean,
  callback: () => void
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setAddDemoSettingsRequest(true));
    try {
      const { status } = await interceptor.patch("/settings", {
        alertDelay: alertDelay,
        eventDelay: eventDelay,
        timezone: timezone,
        isSimulation: isSimulation,
      });

      if (status === 201) {
        callback();
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setAddDemoSettingsRequest(false));
  };
};
