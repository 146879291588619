import { Breadcrumbs, Grid, Switch, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { TitleBar } from "../../../components";

/**
 * Фрагмент с хлебными крошками и заголовком страницы Edit detector
 */

export const Header: React.FC<{ id: string | undefined }> = ({ id }) => (
  <Fragment>
    <Grid lg={12}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/detectors">
          Detectors
        </Link>
        <Typography color="text.primary">{id}</Typography>
      </Breadcrumbs>
    </Grid>
    <Grid item>
      <TitleBar title={`Detector editor ID ${id}`}>
        <Switch defaultChecked={true} sx={{ margin: "5px 0 5px auto" }} />
      </TitleBar>
    </Grid>
  </Fragment>
);
