import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React from "react";

import { ICharts } from "./_charts.types";

/**
 * Компонент Pie Chart
 * @param {id} - название ID тега куда выгрузится Pie Chart.
 * @param {value} - значения объектов массива за счет чего суммируется статистика.
 * @param {category} - заголовки  объектов массива.
 * @param {data} - передаваемый массив.
 */

export const PieChart: React.FC<ICharts> = ({
  id,
  value,
  category,
  data,
  width = 550,
}) => {
  am4core.useTheme(am4themes_animated);
  const chart = am4core.create(`${id}`, am4charts.PieChart);
  chart.data = data;

  const pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = `${value}`;
  pieSeries.dataFields.category = `${category}`;
  pieSeries.dataFields.hidden = "hidden";

  // Disable ticks and labels
  pieSeries.labels.template.disabled = true;
  pieSeries.ticks.template.disabled = true;

  // Disable tooltips
  pieSeries.slices.template.tooltipText = "";

  // Add a legend
  chart.legend = new am4charts.Legend();
  chart.legend.position = "right";
  chart.legend.width = width;
  chart.logo.disabled = true;
  chart.legend.labels.template.fill = am4core.color("#FFF");
  chart.legend.valueLabels.template.fill = am4core.color("#FFF");

  return <div id={id} />;
};
