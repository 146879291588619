import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";

import { TitleBar } from "../../components";
import { useActionsHook } from "../../utils";
import { Tabs } from "./fragments";

export const NoticedPage: React.FC = () => {
  const { getVehiclesNoticedAction } = useActionsHook();

  useEffect(() => {
    getVehiclesNoticedAction();
  }, []);

  return (
    <Container maxWidth={false}>
      <Grid item>
        <TitleBar type="notBordered" title="Noticed" />
      </Grid>
      <Grid item>
        <Tabs />
      </Grid>
    </Container>
  );
};
