import { Box, Chip, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";

import { ImpactRate, TitleBar } from "../../../components";
import { IAlertProps } from "../../../types";
import { CustomizedGrid } from "./_styled.constants";

/**
 * JSX фрагмент EventID Журнала (Journal Event ID) *
 */

export const EventID: React.FC<{
  alertData: IAlertProps | null;
}> = ({ alertData }) => (
  <CustomizedGrid>
    <TitleBar title="Event ID 0420" />
    <Box>
      <Grid sx={{ maxWidth: "760px" }}>
        <Typography variant="h4">{alertData?.alertName}</Typography>
        <Typography variant="body1" color="textSecondary" m="12px 0 38px">
          {alertData?.alertDescription}
        </Typography>
        <List>
          <ListItem>
            <Typography variant="subtitle1" color="textSecondary">
              Event data:
            </Typography>
            <Typography component="p" variant="subtitle1">
              {alertData?.dateCreated}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="subtitle1" color="textSecondary">
              Detector ID:
            </Typography>
            <Typography component="p" variant="subtitle1">
              {alertData?.detectorId}
            </Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid item>
        <Typography color="textSecondary" variant="h6" mb="18px">
          Impact rate:
        </Typography>
        {alertData?.impactRate && <ImpactRate type={alertData?.impactRate} />}
        <Typography color="textSecondary" variant="h6" m="48px 0 8px">
          Affected assets:
        </Typography>
        <Chip label="GPS" color="primary" />
      </Grid>
    </Box>
  </CustomizedGrid>
);
