import React from "react";

import { IIconNavigationBar } from "../../../types";
import { getIconStyle } from "../../../utils";

/**
 * Иконка журнала. Принимает на вход:
 *
 * @param {string} className - Внешний класс для стилизации
 * @param {string} height - Высота
 * @param {string} width - Ширина
 * @param {string} fill - Цвет
 * @param {boolean} isDark - Стиль иконки темный/яркий
 * @returns {JSX.Element}
 */

export const Journal: React.FC<IIconNavigationBar> = ({
  fill = "white",
  width = "36",
  height = "36",
  className = "",
  isDark,
}) => {
  const iconStyle = getIconStyle(isDark);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        opacity="0.05"
        d="M29.25 36H6.75C3.02175 36 0 32.9782 0 29.25V6.75C0 3.02175 3.02175 0 6.75 0H29.25C32.9782 0 36 3.02175 36 6.75V29.25C36 32.9782 32.9782 36 29.25 36Z"
        fill={iconStyle.white}
      />
      <path
        d="M25 11V25H11V11H25ZM26.1 9H9.9C9.4 9 9 9.4 9 9.9V26.1C9 26.5 9.4 27 9.9 27H26.1C26.5 27 27 26.5 27 26.1V9.9C27 9.4 26.5 9 26.1 9Z"
        fill={iconStyle.white}
      />
      <path
        d="M23 13.5C23 13.2239 22.7761 13 22.5 13H17.5C17.2239 13 17 13.2239 17 13.5V14.5C17 14.7761 17.2239 15 17.5 15H22.5C22.7761 15 23 14.7761 23 14.5V13.5Z"
        fill={iconStyle.orange}
      />
      <path
        d="M23 17.5C23 17.2239 22.7761 17 22.5 17H17.5C17.2239 17 17 17.2239 17 17.5V18.5C17 18.7761 17.2239 19 17.5 19H22.5C22.7761 19 23 18.7761 23 18.5V17.5Z"
        fill={iconStyle.orange}
      />
      <path
        d="M23 21.5C23 21.2239 22.7761 21 22.5 21H17.5C17.2239 21 17 21.2239 17 21.5V22.5C17 22.7761 17.2239 23 17.5 23H22.5C22.7761 23 23 22.7761 23 22.5V21.5Z"
        fill={iconStyle.orange}
      />
      <path
        d="M15 13.5C15 13.2239 14.7761 13 14.5 13H13.5C13.2239 13 13 13.2239 13 13.5V14.5C13 14.7761 13.2239 15 13.5 15H14.5C14.7761 15 15 14.7761 15 14.5V13.5Z"
        fill={iconStyle.orange}
      />
      <path
        d="M15 17.5C15 17.2239 14.7761 17 14.5 17H13.5C13.2239 17 13 17.2239 13 17.5V18.5C13 18.7761 13.2239 19 13.5 19H14.5C14.7761 19 15 18.7761 15 18.5V17.5Z"
        fill={iconStyle.orange}
      />
      <path
        d="M15 21.5C15 21.2239 14.7761 21 14.5 21H13.5C13.2239 21 13 21.2239 13 21.5V22.5C13 22.7761 13.2239 23 13.5 23H14.5C14.7761 23 15 22.7761 15 22.5V21.5Z"
        fill={iconStyle.orange}
      />
    </svg>
  );
};
