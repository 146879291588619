import { Dispatch } from "redux";

import {
  AuthUserActionTypes,
  ISetLoginRequest,
  ISetToken,
} from "../../../types";
import { interceptor } from "../../../utils";

/**
 * Добавляет токен пользователя
 *
 * @param {string | null} token - access token.
 * @returns {ISetToken}
 */

const setToken = (token: string | null): ISetToken => ({
  type: AuthUserActionTypes.SET_TOKEN,
  payload: token,
});

/**
 * Задает состояние запроса списка детекторов
 *
 * @param {boolean} isRequest - выполняется ли запрос.
 * @returns {ISetLoginRequest}
 */

const setLoginRequest = (isRequest: boolean): ISetLoginRequest => ({
  type: AuthUserActionTypes.SET_LOGIN_REQUEST,
  payload: isRequest,
});

/**
 * Выполняет запрос на авторизацию пользователя
 */

export const login = (
  login: string,
  password: string,
  callBack?: () => void
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoginRequest(true));
    try {
      const { status, data } = await interceptor.post<{ token: string }>(
        "/login",
        {
          login: login,
          password: password,
        }
      );

      if (status === 200) {
        dispatch(setToken(data.token));
        localStorage.setItem("token", data.token);

        if (callBack) {
          callBack();
        }
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoginRequest(false));
  };
};

/**
 *  Выполняет запрос на выход пользователя
 */

export const logout = (callBack?: () => void) => {
  return async (): Promise<void> => {
    try {
      localStorage.removeItem("token");

      if (callBack) {
        callBack();
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 *  Выполняет запрос на проверку авторизован пользователь или нет
 *  (Не работает, нет рефрештокена с бэкенда)
 */

// export const checkAuth = () => {
//   return async (): Promise<void> => {
//     try {
//       const response = localStorage.getItem("token");
//
//       if (response) {
//
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };
