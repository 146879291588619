import {
  EventsActionTypes,
  IActionEvents,
  IAddEvent,
  IEvents,
  IEventStatistic,
} from "../../types";

// Todo Yusuf: добавить стейты для реквестов (...Request)

interface IInitialState {
  events: IEvents[] | null;
  eventStatistic: IEventStatistic[] | null;
  addEvent: IAddEvent[] | null;
}

const initialState: IInitialState = {
  events: null,
  eventStatistic: null,
  addEvent: null,
};

export const eventsReducer = (
  state = initialState,
  action: IActionEvents
): IInitialState => {
  switch (action.type) {
    case EventsActionTypes.GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
      };
    case EventsActionTypes.GET_EVENT_STATISTIC:
      return {
        ...state,
        eventStatistic: action.payload,
      };
    default:
      return initialState;
  }
};
