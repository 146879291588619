import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Button, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { GridColDef } from "@mui/x-data-grid";
import React from "react";

export const CustomizedButton = styled(Button)({
  width: 200,
  height: 42,
  marginLeft: 20,
});

export const CustomizedBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "30px 0 30px 20px",
});

export const CustomizedTextField = styled(TextField)({
  width: "320px",
  height: "48px",
  marginBottom: "30px",
});

export const users = [
  { label: "Admin" },
  { label: "Analyst" },
  { label: "Hunter" },
];

export const usersTableHeadName: GridColDef[] = [
  {
    field: "username",
    headerName: "Username",
    flex: 0.08,
  },
  {
    field: "email",
    headerName: "E-mail",
    flex: 0.08,
  },
  { field: "Last_login", headerName: "Last login", flex: 0.08 },
  {
    field: "category",
    headerName: "Category",
    flex: 0.08,
  },
  {
    field: "access",
    headerName: "Network access",
    flex: 0.1,
    renderCell: (params: any) =>
      params.value === "true" ? (
        <CancelRoundedIcon color="error" />
      ) : (
        <CheckCircleRoundedIcon color="success" />
      ),
  },
  {
    field: "notification",
    headerName: "Notification by e-mail",
    flex: 0.1,
  },
  {
    field: "activity",
    headerName: "Activity",
  },
];

export const usersData = [
  {
    id: 1,
    username: "Dave",
    email: "test@test.es",
    Last_login: "22/09/2021",
    category: "Admin",
    access: "true",
    notification: "Subscribed",
    activity: true,
  },
  {
    id: 2,
    username: "Sam",
    email: "test@test.es",
    Last_login: "08/09/2020",
    category: "Admin",
    access: "false",
    notification: "Subscribed",
    activity: false,
  },
  {
    id: 3,
    username: "Ann",
    email: "test@test.es",
    Last_login: "12/11/2021",
    category: "User",
    access: "true",
    notification: "Subscribed",
    activity: true,
  },
  {
    id: 4,
    username: "Whinstone",
    email: "test@test.es",
    Last_login: "20/04/2020",
    category: "Analyst",
    access: "true",
    notification: "Subscribed",
    activity: true,
  },
  {
    id: 5,
    username: "Frank",
    email: "test@test.es",
    Last_login: "08/03/2020",
    category: "Hunter",
    access: "false",
    notification: "Subscribed",
    activity: false,
  },
  {
    id: 6,
    username: "Lindsay",
    email: "test@test.es",
    Last_login: "20/04/2020",
    category: "Admin",
    access: "true",
    notification: "Subscribed",
    activity: false,
  },
  {
    id: 7,
    username: "Margarita",
    email: "test@test.es",
    Last_login: "08/03/2020",
    category: "Admin",
    access: "true",
    notification: "Subscribed",
    activity: false,
  },
  {
    id: 8,
    username: "Dante",
    email: "test@test.es",
    Last_login: "20/04/2020",
    category: "Admin",
    access: "false",
    notification: "Subscribed",
    activity: true,
  },
  {
    id: 9,
    username: "Luisa",
    email: "test@test.es",
    Last_login: "08/03/2020",
    category: "Admin",
    access: "true",
    notification: "Subscribed",
    activity: false,
  },
];
