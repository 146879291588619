import React from "react";

import { CustomizedTypography } from "./_styled.constants";

interface ITitleAppBar {
  title: string;
  type?: string;
  className?: string;
}

/**
 * Кастомизированный TitleBar (шапка/header разделов) - возврашает JSX.Element *
 * @param {title} - принимает строку/заголовок шапки *
 * @param {type} - принимает строку/тип шапки которая будет отображатся *
 * @param {className} - принимает строку/класс и принимает стили указанные в styled.constants.ts *
 * @param {children} - дочерние элементы *
 */

export const TitleBar: React.FC<ITitleAppBar> = ({
  title,
  type,
  className,
  children,
}) => {
  switch (type) {
    case "notBordered":
      return (
        <CustomizedTypography
          className={(className = "not_bordered")}
          variant="h6"
        >
          {title}
        </CustomizedTypography>
      );
    default:
      return (
        <CustomizedTypography variant="h6">
          {title}
          {children}
        </CustomizedTypography>
      );
  }
};
