import { Dispatch } from "redux";

import {
  AlertsActionTypes,
  IAlertProps,
  IAlertsProps,
  IAlertsStatistic,
  IAlertsTimeline,
  ISetAlert,
  ISetAlertRequest,
  ISetAlerts,
  ISetAlertsRequest,
  ISetAlertsStatistic,
  ISetAlertsStatisticRequest,
  ISetAlertsTimeline,
  ISetAlertsTimelineRequest,
  ISetTargetAssets,
  ISetTargetAssetsRequest,
  ITargetAssets,
} from "../../../types";
import { interceptor } from "../../../utils";

/**
 * Задает значение информации по списку алертов
 *
 * @param {IAlertsProps} payload - список статистики.
 * @returns {ISetVehiclesStatistic}
 */

const setAlerts = (payload: IAlertsProps[]): ISetAlerts => ({
  type: AlertsActionTypes.SET_ALERTS_SUCCESS,
  payload: payload,
});

/**
 * Задает состояние запроса списка алертов
 *
 * @param {boolean} isRequest - выполняется ли запрос.
 * @returns {ISetAlertsRequest}
 */

const setAlertsRequest = (isRequest: boolean): ISetAlertsRequest => ({
  type: AlertsActionTypes.SET_ALERTS_REQUEST,
  payload: isRequest,
});

/**
 *  Выполняет запрос на получение списка алертов
 */
export const getAlertsActions = (filterData?: any) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setAlertsRequest(true));
    try {
      console.log(filterData);
      const { status, data } = await interceptor.post(
        "/alerts/query",
        filterData
      );

      if (status === 200) {
        dispatch(setAlerts(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setAlertsRequest(false));
  };
};

/**
 * Задает значение информации по алерту
 *
 * @param {IAlertProps} payload - информация по алерту.
 * @returns {ISetAlert}
 */

const setAlert = (payload: IAlertProps): ISetAlert => ({
  type: AlertsActionTypes.SET_ALERT_SUCCESS,
  payload: payload,
});

/**
 * Задает состояние запроса по алерту
 *
 * @param {boolean} isRequest - выполняется ли запрос.
 * @returns {ISetAlertRequest}
 */

const setAlertRequest = (isRequest: boolean): ISetAlertRequest => ({
  type: AlertsActionTypes.SET_ALERT_REQUEST,
  payload: isRequest,
});

/**
 *  Выполняет запрос на получение алерта по ID
 */

export const getAlertActions = (alertId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setAlertRequest(true));
    try {
      const { status, data } = await interceptor.get(`/alerts/${alertId}`);

      if (status === 200) {
        dispatch(setAlert(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setAlertRequest(false));
  };
};

/**
 * Задает значение информации по статистике алертов
 *
 * @param {IAlertsStatistic} payload - список статистики.
 * @returns {ISetAlertsStatistic}
 */

const setAlertsStatistic = (
  payload: IAlertsStatistic[]
): ISetAlertsStatistic => ({
  type: AlertsActionTypes.SET_ALERTS_STATISTIC_SUCCESS,
  payload: payload,
});

/**
 * Задает состояние запроса списка статистики по алертам
 *
 * @param {boolean} isRequest - выполняется ли запрос.
 * @returns {ISetAlertsStatisticRequest}
 */

const setAlertsStatisticRequest = (
  isRequest: boolean
): ISetAlertsStatisticRequest => ({
  type: AlertsActionTypes.SET_ALERTS_STATISTIC_REQUEST,
  payload: isRequest,
});

/**
 *  Выполняет запрос на получение сатистики по алертам
 *   @param {period} - параметр/цикл получения данных.
 */

export const getAlertsStatisticActions = (period: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setAlertsStatisticRequest(true));
    try {
      const { status, data } = await interceptor.post(
        "/alerts/statistic/impact",
        {
          period: period,
        }
      );

      if (status === 200) {
        dispatch(setAlertsStatistic(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setAlertsStatisticRequest(false));
  };
};

/**
 * Задает значение информации по статистике таймлайна Alerts Timeline
 *
 * @param {IAlertsTimeline} payload - список статистики.
 * @returns {ISetAlertsTimeline}
 */

const setAlertsTimeline = (payload: IAlertsTimeline[]): ISetAlertsTimeline => ({
  type: AlertsActionTypes.SET_ALERTS_TIMELINE_SUCCESS,
  payload: payload,
});

/**
 * Задает состояние запроса списка статистики по таймлайну Alerts Timeline
 *
 * @param {boolean} isRequest - выполняется ли запрос.
 * @returns {ISetAlertsStatisticRequest}
 */

const setAlertsTimelineRequest = (
  isRequest: boolean
): ISetAlertsTimelineRequest => ({
  type: AlertsActionTypes.SET_ALERTS_TIMELINE_REQUEST,
  payload: isRequest,
});

/**
 *  Выполняет запрос на получение сатистики по таймлайну Alerts Timeline
 *  @param {period} - параметр/цикл получения данных.
 */

export const getAlertsTimelineActions = (period: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setAlertsTimelineRequest(true));
    try {
      const { status, data } = await interceptor.post(
        "/alerts/statistic/timeline",
        {
          period: period,
        }
      );

      if (status === 200) {
        dispatch(setAlertsTimeline(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setAlertsTimelineRequest(false));
  };
};

/**
 * Задает значение списка Target Assets
 *
 * @param {ITargetAssets} payload - список Target Assets.
 * @returns {ISetTargetAssets}
 */

export const setTargetAssets = (payload: ITargetAssets): ISetTargetAssets => ({
  type: AlertsActionTypes.SET_TARGET_ASSETS_SUCCESS,
  payload: payload,
});

/**
 * Задает состояние списка Target Assets
 *
 * @param {ITargetAssets} payload - список Target Assets.
 * @returns {ISetTargetAssets}
 */

const setTargetAssetsRequest = (
  isRequest: boolean
): ISetTargetAssetsRequest => ({
  type: AlertsActionTypes.SET_TARGET_ASSETS_REQUEST,
  payload: isRequest,
});

/**
 *  Выполняет запрос на получение Target Assets
 */

export const getTargetAssetsActions = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setTargetAssetsRequest(true));
    try {
      const {
        status,
        data: { targetAssets },
      } = await interceptor.get("/alerts/list/assets");

      if (status === 200) {
        dispatch(setTargetAssets(targetAssets));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setTargetAssetsRequest(false));
  };
};
