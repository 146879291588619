import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { ImpactRate, TitleBar } from "../../../../components";
import { useActionsHook, useTypedSelectorHook } from "../../../../utils";
import { DashboardMap } from "./_DashboardMap";
import { fakeDataMap } from "./_googleMap.constants";
import {
  CustomizedBox,
  CustomizedList,
  CustomizedStack,
} from "./_styled.constants";

/**
 * Компонент Map of connected vehicles страницы  Dashboard *
 */

export const VehiclesMap: React.FC = () => {
  // const [status, setStatus] = useState("All");
  // const [alert, setAlert] = useState("All");
  const [showMachineInformation, setShowMachineInformation] = useState(false);
  const [mapId, setMapId] = useState(0);
  const { getVehiclePhotoAction } = useActionsHook();
  const { vehiclePhotoBlob } = useTypedSelectorHook(({ vehicles }) => vehicles);
  const [vehiclePhoto, setVehiclePhoto] = useState<string | null>(null);

  useEffect(() => {
    if (vehiclePhotoBlob) {
      setVehiclePhoto(vehiclePhotoBlob.img);
    }
  }, [vehiclePhotoBlob]);

  useEffect(() => {
    getVehiclePhotoAction(fakeDataMap[mapId].image);
  }, [mapId]);

  function getRandomInt(max: number): number {
    return Math.floor(Math.random() * max);
  }

  // const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setStatus(event.target.value);
  // };
  // const handleChangeAlert = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setAlert(event.target.value);
  // };
  return (
    <Grid item sx={{ width: "100%", height: "594px" }}>
      <Box>
        <TitleBar title="Map of connected vehicles" />
        <Grid display="flex" position="relative">
          {/* <Stack*/}
          {/*  flexDirection="row"*/}
          {/*  alignItems="center"*/}
          {/*  m="10px 0 10px 20px"*/}
          {/*  position="absolute"*/}
          {/* >*/}
          {/*  <Stack flexDirection="row" alignItems="center">*/}
          {/*    <Typography mr="16px">Status</Typography>*/}
          {/*    <TextField*/}
          {/*      id="outlined-select-currency"*/}
          {/*      select*/}
          {/*      label="Status"*/}
          {/*      value={status}*/}
          {/*      onChange={handleChangeStatus}*/}
          {/*      size="small"*/}
          {/*      sx={{ width: 200, zIndex: 3 }}*/}
          {/*    >*/}
          {/*      {options.map((option) => (*/}
          {/*        <MenuItem key={option} value={option}>*/}
          {/*          {option}*/}
          {/*        </MenuItem>*/}
          {/*      ))}*/}
          {/*    </TextField>*/}
          {/*  </Stack>*/}
          {/*  <Stack flexDirection="row" alignItems="center">*/}
          {/*    <Typography m="0 16px 0 40px">Alerts</Typography>*/}
          {/*    <TextField*/}
          {/*      id="outlined-select-currency"*/}
          {/*      select*/}
          {/*      label="Alerts"*/}
          {/*      value={alert}*/}
          {/*      onChange={handleChangeAlert}*/}
          {/*      size="small"*/}
          {/*      sx={{ width: 200 }}*/}
          {/*    >*/}
          {/*      {options.map((option) => (*/}
          {/*        <MenuItem key={option} value={option}>*/}
          {/*          {option}*/}
          {/*        </MenuItem>*/}
          {/*      ))}*/}
          {/*    </TextField>*/}
          {/*  </Stack>*/}
          {/* </Stack>*/}
          {showMachineInformation && (
            <Box
              position="absolute"
              right="0"
              sx={{
                background: "#18181880",
                border: "1px solid rgba(224, 224, 224, 0.1)",
                zIndex: 2,
              }}
            >
              <CustomizedStack>
                <Typography variant="h6" display="flex" alignItems="center">
                  Target vehicle
                  <Typography variant="body2" color="textSecondary" ml="15px">
                    Info
                  </Typography>
                </Typography>
                <IconButton onClick={() => setShowMachineInformation(false)}>
                  <CloseIcon />
                </IconButton>
              </CustomizedStack>
              <CustomizedBox>
                {vehiclePhoto && <img src={vehiclePhoto} alt="Target car" />}
              </CustomizedBox>
              <CustomizedList>
                <ListItem>
                  <Typography component="span" variant="body2">
                    Model:
                  </Typography>
                  <Typography variant="body2">
                    {fakeDataMap[mapId].model}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography component="span" variant="body2">
                    VIN:
                  </Typography>
                  <Typography variant="body2">
                    {fakeDataMap[mapId].vin}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography component="span" variant="body2" />
                  <Typography variant="body2">
                    <br />
                  </Typography>
                </ListItem>
                <ListItem>
                  <span>
                    <ImpactRate type="severe" />
                  </span>
                  <Typography variant="h5">{getRandomInt(26)}</Typography>
                  <Typography variant="body2" color="error.main">
                    +{getRandomInt(36)} new
                  </Typography>
                </ListItem>
                <ListItem>
                  <span>
                    <ImpactRate type="major" />
                  </span>
                  <Typography variant="h5">{getRandomInt(36)}</Typography>
                  <Typography variant="body2" color="warning.main">
                    +{getRandomInt(26)} new
                  </Typography>
                </ListItem>
                <ListItem>
                  <span>
                    <ImpactRate type="moderate" />
                  </span>
                  <Typography variant="h5">{getRandomInt(65)}</Typography>
                  <Typography variant="body2" color="info.main">
                    +{getRandomInt(16)} new
                  </Typography>
                </ListItem>
                <ListItem>
                  <span>
                    <ImpactRate type="negligible" />
                  </span>
                  <Typography variant="h5">{getRandomInt(54)}</Typography>
                  <Typography variant="body2" color="success.main">
                    +{getRandomInt(15)} new
                  </Typography>
                </ListItem>
                <ListItem>
                  <span>
                    <ImpactRate type="no impact" />
                  </span>
                  <Typography variant="h5">{getRandomInt(12)}</Typography>
                  <Typography variant="body2">
                    +{getRandomInt(10)} new
                  </Typography>
                </ListItem>
              </CustomizedList>
            </Box>
          )}
          <DashboardMap
            showMachineInformation={() => {
              setShowMachineInformation(true);
            }}
            setMapId={setMapId}
          />
        </Grid>
      </Box>
    </Grid>
  );
};
