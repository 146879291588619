import { Alert, Drawer, List, ListItemText, styled } from "@mui/material";

/**
 * Стилизованные компоненты для боковой панели с навигацией
 */

export const CustomizedDrawer = styled(Drawer)({
  width: 180,
  flexShrink: 0,

  "& .MuiDrawer-paper": {
    width: 180,
    borderRight: "none",
    boxSizing: "border-box",
    background: "rgba(255, 255, 255, 0.01)",
  },
});

export const CustomizedList = styled(List)({
  paddingTop: 20,
});

export const CustomizedListItemText = styled(ListItemText)({
  span: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#898989",

    svg: {
      marginBottom: "6px",
    },
  },
});

export const CustomAlert = styled(Alert)({
  border: "none",
  width: "140px",
  fontSize: "16px",
  color: "#FFF",
});
