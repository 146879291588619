import { Alert } from "@mui/material";
import React from "react";

/**
 * Адаптированные Алерты под типов - выдача нужного Алерта (Impact Rates)  *
 */

export const ImpactRate: React.FC<{ type: string }> = ({ type }) => {
  switch (type.toLowerCase()) {
    case "severe":
      return (
        <Alert variant="filled" severity="error">
          Severe
        </Alert>
      );
    case "major":
      return (
        <Alert variant="filled" severity="warning">
          Major
        </Alert>
      );
    case "moderate":
      return (
        <Alert variant="filled" severity="info">
          Moderate
        </Alert>
      );
    case "negligible":
      return (
        <Alert variant="filled" severity="success">
          Negligible
        </Alert>
      );
    default:
      return (
        <Alert variant="outlined" severity="success" sx={{ width: "168px" }}>
          No impact
        </Alert>
      );
  }
};
