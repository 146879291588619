import { TabContext, TabPanel } from "@mui/lab";
import { Container, Tab, Tabs } from "@mui/material";
import React from "react";

import { TitleBar } from "../../components";
import { Alerts } from "./Alerts";
import { Events } from "./Events";
import { VehicleModels } from "./VehicleModels";

/**
 * Страница Analysis *
 */

export const AnalysisPage: React.FC = () => {
  const [value, setValue] = React.useState("Event vehicle models");

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    setValue(newValue);
  };

  return (
    <Container maxWidth={false}>
      <TitleBar type="notBordered" title={`${value}`} />
      <TabContext value={value}>
        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
          <Tab value="Event vehicle models" label="Vehicle models" />
          <Tab value="Event analysis" label="Event" />
          <Tab value="Event alerts" label="Alerts" />
        </Tabs>
        <TabPanel value="Event vehicle models" sx={{ p: 0 }}>
          <VehicleModels />
        </TabPanel>
        <TabPanel value="Event analysis" sx={{ p: 0 }}>
          <Events />
        </TabPanel>
        <TabPanel value="Event alerts" sx={{ p: 0 }}>
          <Alerts />
        </TabPanel>
      </TabContext>
    </Container>
  );
};
