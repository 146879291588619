export const targetAssets = [
  { title: "GPS" },
  { title: "ABS" },
  { title: "GLONAS" },
];

export const impacts = [
  "severe",
  "major",
  "moderate",
  "negligible",
  "no impact",
];

export const typeSignals = ["Only alerts", "Only events"];
export const statusSignals = ["Checked", "Unchecked"];
