import WarningIcon from "@mui/icons-material/Warning";
import { Box, styled } from "@mui/material";

export const CustomizedCheckIconBox = styled(Box)(
  () => `
  align-items: center;
  background-color: #4CAF50;
  border-radius: 50px;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 8px;
`
);

export const CustomizedWarningIcon = styled(WarningIcon)(
  () => `
  height: 19px;
  margin-right: 8px;
  width: 22px;
`
);
