import { Box, Toolbar } from "@mui/material";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { HeaderBar, Sidebar } from "./fragments";

/**
 * Обертка для всех страниц
 *
 * @returns {React.FC}
 */

export const CommonShell: React.FC = () => {
  const location = useLocation();
  const localStorageRef = localStorage.getItem("token");

  if (!localStorageRef) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <HeaderBar />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, pt: 2 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};
