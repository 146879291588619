import { Container, Grid } from "@mui/material";
import { DataGrid, GridRowsProp } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import { TitleBar } from "../../components";
import { detectorsTableHeadName } from "../../constants";
import { useActionsHook, useTypedSelectorHook } from "../../utils";

export const DetectorsPage: React.FC = () => {
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [detectorsList, setDetectorsList] = useState<GridRowsProp>([]);
  const { getDetectorsAction } = useActionsHook();
  const { detectors } = useTypedSelectorHook(({ detectors }) => detectors);

  useEffect(() => {
    getDetectorsAction();
  }, []);

  useEffect(() => {
    if (detectors) {
      setDetectorsList(detectors);
    }
  }, [detectors]);

  return (
    <Container maxWidth={false}>
      <Grid item>
        <TitleBar title="Detectors" />
        {/* <Stack flexDirection="row" justifyContent="space-between" p="12px 0">*/}
        {/*  <TextField*/}
        {/*    sx={{ width: 330 }}*/}
        {/*    size="small"*/}
        {/*    label="Search detectors"*/}
        {/*    placeholder="Search detectors"*/}
        {/*  />*/}

        {/*  <Button variant="outlined" startIcon={<AddBoxIcon />}>*/}
        {/*    <Link to="/detectors/edit/1">Create new</Link>*/}
        {/*  </Button>*/}
        {/* </Stack>*/}
        <br />
        <br />
      </Grid>
      <Grid item>
        {detectorsList.length !== 0 && (
          <DataGrid
            autoHeight
            disableColumnMenu
            rowsPerPageOptions={[25, 50, 100]}
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            columns={detectorsTableHeadName.map((column) => ({
              ...column,
              sortable: false,
            }))}
            rows={detectorsList}
          />
        )}
      </Grid>
    </Container>
  );
};
