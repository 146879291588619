import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";

import {
  CustomizedBox,
  CustomizedButton,
  CustomizedTextField,
  users,
  usersData,
  usersTableHeadName,
} from "./_users.constants";

// Todo Yusuf: описание
export const Users: React.FC = () => {
  const [isShowFilterContent, setIsShowFilterContent] =
    useState<boolean>(false);

  const eventIsShowFilterContent = (): void => {
    return setIsShowFilterContent(!isShowFilterContent);
  };

  return (
    <Grid>
      <CustomizedBox>
        <Stack flexDirection="row" alignItems="center">
          <FormLabel>Action</FormLabel>
          <CustomizedButton
            variant="outlined"
            disabled
            startIcon={<PersonAddIcon color="primary" />}
          >
            Add new user
          </CustomizedButton>
          <CustomizedButton
            variant="outlined"
            disabled
            startIcon={<EditIcon color="primary" />}
          >
            Edit
          </CustomizedButton>
          <CustomizedButton
            variant="outlined"
            disabled
            startIcon={<DeleteForeverIcon color="primary" />}
          >
            Delete user
          </CustomizedButton>
        </Stack>
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => eventIsShowFilterContent()}
        >
          More Filters
        </Button>
      </CustomizedBox>
      {isShowFilterContent && (
        <Grid>
          <Stack flexDirection="row">
            <FormGroup>
              <Autocomplete
                disablePortal
                options={users}
                size="medium"
                renderInput={(params) => (
                  <CustomizedTextField {...params} label="Access category" />
                )}
              />
              <FormControlLabel
                sx={{ mt: "10px" }}
                control={<Checkbox defaultChecked />}
                label="Network access"
              />
              <FormControlLabel
                sx={{ mt: "30px" }}
                control={<Checkbox defaultChecked />}
                label="Notification by e-mail"
              />
            </FormGroup>
            <FormGroup sx={{ m: "0 45px" }}>
              <CustomizedTextField label="Login" variant="outlined" />
              <CustomizedTextField label="E-mail" variant="outlined" />
            </FormGroup>
            <FormGroup>
              <CustomizedTextField label="Password" variant="outlined" />
              <CustomizedTextField label="Repeat password" variant="outlined" />
            </FormGroup>
          </Stack>
          <Box m="50px 0 20px">
            <Button
              variant="contained"
              size="large"
              sx={{ width: "160px", marginRight: "20px" }}
            >
              Save
            </Button>
            <Button variant="outlined" size="large" sx={{ width: "160px" }}>
              Cancel
            </Button>
          </Box>
        </Grid>
      )}
      <DataGrid
        autoHeight
        disableColumnMenu
        hideFooterSelectedRowCount={false}
        rows={usersData}
        columns={usersTableHeadName.map((column) => ({
          ...column,
          sortable: false,
        }))}
        checkboxSelection
      />
    </Grid>
  );
};
