import {
  Autocomplete,
  Box,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";

export const Logic: React.FC = () => {
  const options = [
    { label: "Option 1" },
    { label: "Option 2" },
    { label: "Option 2" },
    { label: "Option 3" },
    { label: "Option 4" },
  ];
  return (
    <StyledBox>
      <Typography variant="h5">Logic</Typography>
      <Typography color="textSecondary" variant="body1" m="15px 0 25px">
        Pick one of options
      </Typography>
      <Stack flexDirection="row" alignItems="center">
        <Autocomplete
          disablePortal
          options={options}
          size="medium"
          sx={{ width: 320 }}
          renderInput={(params) => <TextField {...params} label="Parameter" />}
        />
        <Autocomplete
          disablePortal
          options={options}
          size="medium"
          sx={{ width: 320, margin: "0 20px" }}
          renderInput={(params) => <TextField {...params} label="State" />}
        />
        <Autocomplete
          disablePortal
          options={options}
          size="medium"
          sx={{ width: 320 }}
          renderInput={(params) => <TextField {...params} label="Value" />}
        />
      </Stack>
      <Box m="30px 0">
        <Chip clickable label="And" variant="outlined" />
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  marginTop: "20px",
  padding: "50px 0 50px 16px",
  borderTop: "1px solid rgba(133, 133, 133, 0.2)",
});
