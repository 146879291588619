import "core-js/stable";
import "regenerator-runtime/runtime";

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { PieChart } from "../../../components/molecules/Charts";
import { IVehiclesStatistic } from "../../../types";
import { useActionsHook, useTypedSelectorHook } from "../../../utils";

/**
 * Вкладка Vehicle Models страницы Analysis *
 */

export const VehicleModels: React.FC = () => {
  const { vehiclesStatistic } = useTypedSelectorHook(
    ({ vehicles }) => vehicles
  );
  const { getVehiclesStatistic } = useActionsHook();
  const [vehicles, setVehicles] = useState<IVehiclesStatistic[]>([]);

  useEffect(() => {
    getVehiclesStatistic();
  }, []);

  useEffect(() => {
    if (vehiclesStatistic) {
      setVehicles(
        vehiclesStatistic.map((item) => {
          return {
            ...item,
          };
        })
      );
    }
  }, [vehiclesStatistic]);

  return (
    <Grid container spacing={2}>
      <Grid item width="100%">
        <Grid sx={{ width: 1000, margin: "50px auto 0" }}>
          <PieChart
            id="vehiclePieChart"
            value="value"
            category="category"
            data={vehicles}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
