import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Switch } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

import { ImpactRate } from "../components";

/**
 * Пункты заголовков таблицы Detectors
 */

export const detectorsTableHeadName: GridColDef[] = [
  {
    field: "isActive",
    headerName: "Activity",
    renderCell: (params: GridRenderCellParams) => (
      <Switch defaultChecked={params.value} />
    ),
    flex: 0.07,
  },
  { field: "id", headerName: "Detector ID", flex: 0.09 },
  {
    field: "alertName",
    headerName: "Alert name",
    renderCell: (params: GridRenderCellParams) => (
      <Link to={`/detectors/${params.row.id}`}>{params.value}</Link>
    ),
    flex: 0.3,
  },
  { field: "alertDescription", headerName: "Description", flex: 0.2 },
  { field: "developer", headerName: "Developer", flex: 0.2 },
  {
    field: "impactRate",
    headerName: "Impact rate",
    renderCell: (params: GridRenderCellParams) => (
      <ImpactRate type={params.value} />
    ),
    flex: 0.2,
  },
  {
    field: "lastUpdated",
    headerName: "Last update",
    renderCell: (params: any) =>
      format(new Date(params.value), "dd/MM/yyyy HH:mm:ss"),
    flex: 0.2,
  },
  { field: "trigger", headerName: "Triggering", flex: 0.05 },
  {
    field: "edit",
    headerName: "Edit",
    renderCell: (params: GridRenderCellParams) => (
      <Link to={`/detectors/edit/${params.row.id}`}>
        <IconButton aria-label="delete" size="medium">
          <EditIcon fontSize="inherit" />
        </IconButton>
      </Link>
    ),
    flex: 0.01,
  },
];
