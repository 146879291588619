import React from "react";

import { IIconNavigationBar } from "../../../types";
import { getIconStyle } from "../../../utils";

/**
 * Иконка пользователи. Принимает на вход:
 *
 * @param {string} className - Внешний класс для стилизации
 * @param {string} height - Высота
 * @param {string} width - Ширина
 * @param {string} fill - Цвет
 * @param {boolean} isDark - Стиль иконки темный/яркий
 * @returns {JSX.Element}
 */

export const Users: React.FC<IIconNavigationBar> = ({
  fill = "white",
  width = "36",
  height = "36",
  className = "",
  isDark,
}) => {
  const iconStyle = getIconStyle(isDark);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        opacity="0.05"
        d="M0 7.2C0 3.22355 3.22355 0 7.2 0H28.8C32.7764 0 36 3.22355 36 7.2V28.8C36 32.7764 32.7764 36 28.8 36H7.2C3.22355 36 0 32.7764 0 28.8V7.2Z"
        fill={iconStyle.white}
      />
      <path
        d="M22 17C23.66 17 24.99 15.66 24.99 14C24.99 12.34 23.66 11 22 11C20.34 11 19 12.34 19 14C19 15.66 20.34 17 22 17ZM22 19C21.71 19 21.38 19.02 21.03 19.05C22.19 19.89 23 21.02 23 22.5V25H29V22.5C29 20.17 24.33 19 22 19Z"
        fill={iconStyle.white}
      />
      <path
        d="M16.99 14C16.99 15.66 15.66 17 14 17C12.34 17 11 15.66 11 14C11 12.34 12.34 11 14 11C15.66 11 16.99 12.34 16.99 14Z"
        fill={iconStyle.orange}
      />
      <path
        d="M7 22.5C7 20.17 11.67 19 14 19C16.33 19 21 20.17 21 22.5V25H7V22.5Z"
        fill={iconStyle.orange}
      />
    </svg>
  );
};
