import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { TitleBar } from "../../components";
import { useActionsHook, useTypedSelectorHook } from "../../utils";
import {
  Container,
  Content,
  Description,
  SendButton,
  Title,
  Wrapper,
} from "./_styled.constants";

interface State {
  email: string;
  password: string;
  showPassword: boolean;
}

export const LoginPage: React.FC = () => {
  const [values, setValues] = useState<State>({
    email: "",
    password: "",
    showPassword: false,
  });
  const navigate = useNavigate();

  const { login } = useActionsHook();
  const { loginRequest } = useTypedSelectorHook(({ user }) => user);

  const eventLogin = (): void => {
    if (values.email !== "" && values.password !== "") {
      login(values.email, values.password, () => {
        navigate("/", { replace: true });
      });
    }
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = (): void => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
  };

  if (localStorage.getItem("token")) {
    return <Navigate to="/" replace />;
  }

  return (
    <Wrapper>
      <Container>
        <TitleBar title="Log in" />
        <Content>
          <Title variant="h4">
            Welcome to <span>AutoVisor SIEM</span>
          </Title>
          <Description variant="body1">
            Login to your account to browse through project and explore our
            tools
          </Description>
          <TextField
            label="E-mail"
            fullWidth
            value={values.email}
            onChange={handleChange("email")}
            style={{ marginBottom: "20px" }}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <SendButton
            variant="contained"
            size="large"
            onClick={() => eventLogin()}
            disabled={loginRequest}
          >
            Log in
          </SendButton>
        </Content>
      </Container>
    </Wrapper>
  );
};
