import { TabContext, TabPanel } from "@mui/lab";
import { Container, Tab, Tabs } from "@mui/material";
import React from "react";

import { TitleBar } from "../../components";
import { Setting } from "./setting";
import { Users } from "./users";

// Todo Yusuf: описание
export const UsersPage: React.FC = () => {
  const [value, setValue] = React.useState("List of users");

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    setValue(newValue);
  };

  return (
    <Container maxWidth={false}>
      <TitleBar type="notBordered" title={`${value}`} />

      <TabContext value={value}>
        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
          <Tab value="List of users" label="Users" />
          <Tab value="Setting" label="Setting" disabled />
        </Tabs>
        <TabPanel value="List of users" sx={{ p: 0 }}>
          <Users />
        </TabPanel>
        <TabPanel value="Setting" sx={{ p: 0 }}>
          <Setting />
        </TabPanel>
      </TabContext>
    </Container>
  );
};
