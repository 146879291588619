import { Box, Container, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import { TitleBar } from "../../components";
import { alertsTableHeadName, useStyles } from "../../constants";
import { IAlertsProps } from "../../types";
import { useActionsHook, useTypedSelectorHook } from "../../utils";
import { AlertsAnalytics, AlertsTimeline, VehiclesMap } from "./fragments";

/**
 * Страница  Dashboard *
 */

export const DashboardPage: React.FC = () => {
  const [pageSize, setPageSize] = React.useState<number>(5);

  const classes = useStyles();
  const { getAlertsActions } = useActionsHook();
  const { alerts } = useTypedSelectorHook(({ alerts }) => alerts);
  const [alertsList, setAlertsList] = useState<IAlertsProps[]>([]);

  useEffect(() => {
    getAlertsActions();
  }, []);

  useEffect(() => {
    if (alerts) {
      setAlertsList(alerts);
    }
  }, [alerts]);

  return (
    <Container maxWidth={false}>
      <Grid
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb="40px"
      >
        <VehiclesMap />
        <Box sx={{ width: "520px", marginLeft: "20px", flex: "none" }}>
          <AlertsAnalytics />
          <AlertsTimeline />
        </Box>
      </Grid>
      <Grid item>
        <TitleBar title="Journal" />
      </Grid>
      <Grid item>
        <DataGrid
          autoHeight
          disableColumnMenu
          className={classes.root}
          hideFooterSelectedRowCount={false}
          rows={alertsList}
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          columns={alertsTableHeadName.map((column) => ({
            ...column,
            sortable: false,
          }))}
          checkboxSelection
          getRowClassName={(params) =>
            `super-app-theme--${params.getValue(params.id, "isChecked")}`
          }
        />
      </Grid>
    </Container>
  );
};
