import React from "react";

import { IIconNavigationBar } from "../../../types";
import { getIconStyle } from "../../../utils";

/**
 * Иконка заметкок. Принимает на вход:
 *
 * @param {string} className - Внешний класс для стилизации
 * @param {string} height - Высота
 * @param {string} width - Ширина
 * @param {string} fill - Цвет
 * @param {boolean} isDark - Стиль иконки темный/яркий
 * @returns {JSX.Element}
 */

export const Noticed: React.FC<IIconNavigationBar> = ({
  fill = "white",
  width = "40",
  height = "40",
  className = "",
  isDark,
}) => {
  const iconStyle = getIconStyle(isDark);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_1593_12297)">
        <path
          opacity="0.05"
          d="M33 40H8C3.8575 40 0.5 36.6425 0.5 32.5V7.5C0.5 3.3575 3.8575 0 8 0H33C37.1425 0 40.5 3.3575 40.5 7.5V32.5C40.5 36.6425 37.1425 40 33 40Z"
          fill={iconStyle.white}
        />
        <path
          d="M20.2411 24.031C20.4 23.9351 20.599 23.9351 20.7579 24.031L23.9736 25.9719C24.3523 26.2004 24.8194 25.8609 24.7189 25.4302L23.8655 21.7721C23.8234 21.5913 23.8848 21.4021 24.0251 21.2806L26.8669 18.8186C27.2012 18.529 27.0225 17.9799 26.5818 17.9425L22.8409 17.6251C22.6561 17.6095 22.4951 17.4928 22.4228 17.322L20.9598 13.8704C20.7876 13.4638 20.2114 13.4639 20.0391 13.8704L18.5762 17.322C18.5038 17.4928 18.3429 17.6095 18.1581 17.6251L14.4171 17.9425C13.9764 17.9799 13.7977 18.529 14.132 18.8186L16.9739 21.2806C17.1142 21.4021 17.1756 21.5913 17.1334 21.7721L16.2801 25.4302C16.1796 25.8609 16.6467 26.2004 17.0254 25.9719L20.2411 24.031Z"
          fill={iconStyle.orange}
        />
        <rect
          x="11.25"
          y="10.75"
          width="18.5"
          height="18.5"
          rx="1.25"
          stroke={iconStyle.white}
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1593_12297">
          <rect
            width={width}
            height={height}
            fill={iconStyle.white}
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
