/**
 * Типы ключей объекта Vehicles
 */

export interface IVehiclesProps {
  id: number;
  vin: string;
  brand: string;
  model: string;
  lastUpdated: string;
  alertsNumber: number;
  isChecked: boolean;
  isNoticed: boolean;
}

/**
 * Тип для масива с автомобилями
 */

export type IVehiclesBrand = string[];

/**
 * Типы ключей объекта моделей сортированных по бренду
 */

export type IVehiclesModels = [
  {
    brand: string;
    model: string[];
  }
];

/**
 * Типы ключей объекта винов сортированных по модели
 */

export type IVehiclesVins = string[];

export /**
 * Типы ключей объекта vehiclesStatisticBrands
 */

interface IVehiclesStatistic {
  category: string;
  value: number;
}

// типы картинки

export interface IVehiclePhoto {
  img: string;
}

/**
 * Типы для экшена и редьюсера Vehicles
 */

export enum VehiclesActionTypes {
  GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS",
  GET_VEHICLES_REQUEST = "GET_VEHICLES_REQUEST",
  GET_VEHICLES_BRAND_SUCCESS = "GET_VEHICLES_BRAND_SUCCESS",
  GET_VEHICLES_BRAND_REQUEST = "GET_VEHICLES_BRAND_REQUEST",
  GET_VEHICLES_MODELS_SUCCESS = "GET_VEHICLES_MODELS_SUCCESS",
  GET_VEHICLES_VINS_SUCCESS = "GET_VEHICLES_VINS_SUCCESS",
  SET_VEHICLE_SUCCESS = "SET_VEHICLE_SUCCESS",
  SET_VEHICLE_REQUEST = "SET_VEHICLE_REQUEST",
  SET_VEHICLE_PHOTO_SUCCESS = "SET_VEHICLE_PHOTO_SUCCESS",
  SET_VEHICLE_PHOTO_REQUEST = "SET_VEHICLE_PHOTO_REQUEST",
  SET_VEHICLES_NOTICED_SUCCESS = "SET_VEHICLES_NOTICED_SUCCESS",
  SET_VEHICLES_NOTICED_REQUEST = "SET_VEHICLES_NOTICED_REQUEST",
  SET_VEHICLES_STATISTIC_SUCCESS = "SET_VEHICLES_STATISTIC_SUCCESS",
  SET_VEHICLES_STATISTIC_REQUEST = "SET_VEHICLES_STATISTIC_REQUEST",
}

export interface ISetVehicles {
  type: VehiclesActionTypes.GET_VEHICLES_SUCCESS;
  payload: IVehiclesProps[];
}

export interface ISetVehiclesRequest {
  type: VehiclesActionTypes.GET_VEHICLES_REQUEST;
  payload: boolean;
}

export interface ISetVehiclesBrand {
  type: VehiclesActionTypes.GET_VEHICLES_BRAND_SUCCESS;
  payload: IVehiclesBrand;
}

export interface ISetVehiclesBrandRequest {
  type: VehiclesActionTypes.GET_VEHICLES_BRAND_REQUEST;
  payload: boolean;
}

export interface ISetVehiclesModels {
  type: VehiclesActionTypes.GET_VEHICLES_MODELS_SUCCESS;
  payload: IVehiclesModels;
}

export interface ISetVehiclesVINS {
  type: VehiclesActionTypes.GET_VEHICLES_VINS_SUCCESS;
  payload: IVehiclesVins;
}

export interface ISetVehiclesNoticed {
  type: VehiclesActionTypes.SET_VEHICLES_NOTICED_SUCCESS;
  payload: IVehiclesProps[];
}

export interface ISetVehiclesNoticedRequest {
  type: VehiclesActionTypes.SET_VEHICLES_NOTICED_REQUEST;
  payload: boolean;
}

export interface ISetVehicle {
  type: VehiclesActionTypes.SET_VEHICLE_SUCCESS;
  payload: IVehiclesProps;
}

export interface ISetVehicleRequest {
  type: VehiclesActionTypes.SET_VEHICLE_REQUEST;
  payload: boolean;
}

export interface ISetVehiclePhoto {
  type: VehiclesActionTypes.SET_VEHICLE_PHOTO_SUCCESS;
  payload: IVehiclePhoto;
}

export interface ISetVehiclePhotoRequest {
  type: VehiclesActionTypes.SET_VEHICLE_PHOTO_REQUEST;
  payload: boolean;
}

export interface ISetVehiclesStatistic {
  type: VehiclesActionTypes.SET_VEHICLES_STATISTIC_SUCCESS;
  payload: IVehiclesStatistic[];
}

export interface ISetVehiclesStatisticRequest {
  type: VehiclesActionTypes.SET_VEHICLES_STATISTIC_REQUEST;
  payload: boolean;
}

export type IActionVehicles =
  | ISetVehicles
  | ISetVehiclesRequest
  | ISetVehiclesBrand
  | ISetVehiclesBrandRequest
  | ISetVehiclesNoticed
  | ISetVehiclesNoticedRequest
  | ISetVehicle
  | ISetVehicleRequest
  | ISetVehiclesStatistic
  | ISetVehiclesStatisticRequest
  | ISetVehiclePhoto
  | ISetVehiclePhotoRequest
  | ISetVehiclesModels
  | ISetVehiclesVINS;
