import "core-js/stable";
import "regenerator-runtime/runtime";

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { PieChart } from "../../../components/molecules/Charts";
import { IEventStatistic } from "../../../types";
import { useActionsHook, useTypedSelectorHook } from "../../../utils";

/**
 * Вкладка Events страницы Analysis *
 */

export const Events: React.FC = () => {
  const { eventStatistic } = useTypedSelectorHook(({ events }) => events);
  const { getEventStatisticActions } = useActionsHook();
  const [events, setEvents] = useState<IEventStatistic[]>([]);

  useEffect(() => {
    getEventStatisticActions();
  }, []);

  useEffect(() => {
    if (eventStatistic) {
      setEvents(eventStatistic.filter((item) => item.value > 0));
    }
  }, [eventStatistic]);

  return (
    <Grid container spacing={2}>
      <Grid item width="100%">
        <Grid sx={{ width: 1000, margin: "50px auto 0" }}>
          <PieChart
            id="eventPieChart"
            value="value"
            category="category"
            data={events}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
