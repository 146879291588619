import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Stack } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import bgNotFoundPage from "../../assets/images/bg-notfound-page.svg";
import {
  Content,
  CustomButton,
  Description,
  SubTitle,
  Title,
} from "./_styled.constants";

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <Box
        sx={{
          mt: "auto",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "180px 0 0",
        }}
      >
        <Title>404</Title>
        <SubTitle>Whoops!</SubTitle>
        <Description>This page doesn’t exist.</Description>
        <Stack direction="row" spacing={4}>
          <CustomButton
            startIcon={<ArrowBackIcon />}
            variant="outlined"
            size="large"
            onClick={() => navigate(-1)}
          >
            Go back
          </CustomButton>
          <Link to="/">
            <CustomButton
              endIcon={<ArrowForwardIcon />}
              variant="outlined"
              size="large"
            >
              Go home
            </CustomButton>
          </Link>
        </Stack>
      </Box>
      <img src={bgNotFoundPage} alt="404" style={{ marginTop: "auto" }} />
    </Content>
  );
};
