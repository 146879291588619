import { ThemeProvider } from "@mui/material";
import React from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import { CommonShell } from "./components";
import { theme } from "./constants";
import {
  AlertPage,
  AlertsPage,
  AnalysisPage,
  DashboardPage,
  DetectorEditPage,
  DetectorPage,
  DetectorsPage,
  LoginPage,
  NotFoundPage,
  NoticedPage,
  SettingsPage,
  UsersPage,
  VehiclePage,
  VehiclesPage,
} from "./pages";

export const App: React.FC = () => (
  <HashRouter>
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<CommonShell />}>
          <Route index element={<Navigate to="/vehicles" replace />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="vehicles" element={<VehiclesPage />} />
          <Route path="journal" element={<AlertsPage />} />
          <Route path="journal/:id" element={<AlertPage />} />
          <Route path="vehicles/:id" element={<VehiclePage />} />
          <Route path="noticed" element={<NoticedPage />} />
          <Route path="detectors" element={<DetectorsPage />} />
          <Route path="detectors/:id" element={<DetectorPage />} />
          <Route path="detectors/edit/:id" element={<DetectorEditPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="analysis" element={<AnalysisPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  </HashRouter>
);
