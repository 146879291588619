import React from "react";

import { IIconNavigationBar } from "../../../types";
import { getIconStyle } from "../../../utils";

/**
 * Иконка аналилитики. Принимает на вход:
 *
 * @param {string} className - Внешний класс для стилизации
 * @param {string} height - Высота
 * @param {string} width - Ширина
 * @param {string} fill - Цвет
 * @param {boolean} isDark - Стиль иконки темный/яркий
 * @returns {JSX.Element}
 */

export const Analysis: React.FC<IIconNavigationBar> = ({
  fill = "white",
  width = "36",
  height = "36",
  className = "",
  isDark,
}) => {
  const iconStyle = getIconStyle(isDark);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        opacity="0.05"
        d="M29.25 36H6.75C3.02175 36 0 32.9782 0 29.25V6.75C0 3.02175 3.02175 0 6.75 0H29.25C32.9782 0 36 3.02175 36 6.75V29.25C36 32.9782 32.9782 36 29.25 36Z"
        fill={iconStyle.white}
      />
      <path
        d="M18.6728 9.03515C18.6153 9.03099 18.5664 9.07671 18.5664 9.13431V17.6421C18.5664 17.7925 18.6003 17.9409 18.6656 18.0764L22.3711 25.7617C22.3994 25.8073 22.4587 25.8227 22.5051 25.7958C25.1963 24.239 27.0039 21.3317 27.0039 18.0056C27.0039 13.2638 23.3251 9.37189 18.6728 9.03515Z"
        fill={iconStyle.orange}
      />
      <path
        d="M17.6711 18.6246C17.6544 18.59 17.6194 18.5681 17.581 18.5681H9.12586C9.06822 18.5681 9.02247 18.6167 9.02668 18.6742C9.18445 20.8309 10.1023 22.8311 11.6442 24.3652C12.4913 25.2202 13.4813 25.8806 14.5545 26.3227C15.6345 26.7727 16.8045 27.0056 18.0049 27.0056C19.168 27.0056 20.274 26.7869 21.2964 26.3783C21.3498 26.357 21.3735 26.2948 21.3485 26.243L17.6711 18.6246Z"
        fill={iconStyle.white}
      />
      <path
        d="M9.03238 17.3368C9.02826 17.3942 9.07397 17.4431 9.13155 17.4431H17.3404C17.3956 17.4431 17.4404 17.3983 17.4404 17.3431V9.13424C17.4404 9.07666 17.3915 9.03095 17.3341 9.03506C12.9043 9.35267 9.34998 12.907 9.03238 17.3368Z"
        fill={iconStyle.white}
      />
    </svg>
  );
};
