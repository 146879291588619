import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import { IEvents } from "../../../types";
import { useActionsHook, useTypedSelectorHook } from "../../../utils";
import { eventsHeadName } from "./_EventSetting.constants";

/**
 * Вкладка настроек событии/Event Setting
 */

interface IArraySelect {
  value: string;
  label: string;
}

const generationDataForSelect = (array: string[]): IArraySelect[] => {
  return array.map((item: string) => ({
    value: item,
    label: item,
  }));
};

export const EventSettingsPage: React.FC = () => {
  const { events } = useTypedSelectorHook(({ events }) => events);
  const { getEventsActions, setAddEventActions, getTargetAssetsActions } =
    useActionsHook();
  const [eventList, setEventList] = useState<IEvents[]>([]);

  const { alertTargetAssets } = useTypedSelectorHook(({ alerts }) => alerts);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectTargetAssets, setSelectTargetAssets] = useState<IArraySelect[]>(
    []
  );
  const [eventName, setEventName] = useState<string>("");
  const [asset, setAsset] = useState<string>("");

  const handleEvent = (event: any) => {
    setEventName(event.target.value);
  };

  const addedEvent = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (eventName && asset !== "") {
      setAddEventActions(eventName, asset, () => {
        getEventsActions();
      });
    }

    setAsset("");
    setEventName("");
  };

  useEffect(() => {
    getEventsActions();
    getTargetAssetsActions();
  }, []);

  useEffect(() => {
    if (alertTargetAssets) {
      setSelectTargetAssets((prevState) => [
        ...generationDataForSelect(alertTargetAssets),
      ]);
    }
  }, [alertTargetAssets]);

  useEffect(() => {
    if (events) {
      setEventList(
        events.map((item) => {
          return {
            ...item,
          };
        })
      );
    }
  }, [events]);

  return (
    <Grid>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        p="20px 10px"
      >
        <Typography variant="body1" color="textSecondary">
          Select the set of the events that will be shown in the event log
        </Typography>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{ width: 200, height: 40 }}
          onClick={() => setOpen(!isOpen)}
        >
          Add Event
        </Button>
      </Stack>
      {isOpen && (
        <Box p="10px">
          <Typography variant="body1" color="textSecondary">
            Add new event type
          </Typography>
          <form onSubmit={addedEvent}>
            <FormControl
              sx={{ flexDirection: "row", alignItems: "center", p: "20px 0" }}
            >
              <Stack flexDirection="row" alignItems="center">
                <Typography variant="body1" color="textSecondary" width="114px">
                  Event name:
                </Typography>
                <TextField
                  label="Event name"
                  placeholder="Event name"
                  size="small"
                  value={eventName}
                  sx={{ width: 250 }}
                  fullWidth
                  onChange={handleEvent}
                />
              </Stack>
              <Stack flexDirection="row" alignItems="center" m="0 40px">
                <Typography variant="body1" color="textSecondary" width="114px">
                  Target asset:
                </Typography>
                <Autocomplete
                  disablePortal
                  options={selectTargetAssets}
                  size="small"
                  sx={{ width: 250 }}
                  inputValue={asset}
                  onInputChange={(_, val) => {
                    setAsset(val);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Target asset" />
                  )}
                />
              </Stack>
              <Button
                variant="contained"
                component="button"
                type="submit"
                sx={{ width: 200, height: 40 }}
                onSubmit={addedEvent}
              >
                Save
              </Button>
            </FormControl>
          </form>
        </Box>
      )}
      <Grid item>
        <DataGrid
          autoHeight
          disableColumnMenu
          hideFooterSelectedRowCount={false}
          rows={eventList}
          columns={eventsHeadName.map((column) => ({
            ...column,
            sortable: false,
          }))}
          checkboxSelection={false}
        />
        {/* Todo Yusuf: это должно быть в компоненте Футер который принимает в себя чилдрены и позиционирется всегда внизу страницы*/}
        <Box m="30px 0">
          <Button
            variant="contained"
            size="large"
            sx={{ width: "160px", marginRight: "20px" }}
          >
            Save
          </Button>
          <Button variant="outlined" size="large" sx={{ width: "160px" }}>
            Cancel
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
