import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";

import { CustomAutocomplete, ImpactRate } from "../../../../components";
import { IGeneratingAutocompleteObjectArray } from "../../../../types";
import {
  impacts,
  statusSignals,
  typeSignals,
} from "../../Filter/_filter.constants";
import { AlertsFilterHeader } from "./_FilterHeader";

interface IAlertsFilter {
  setFilteringData: any;
  targetAssets: IGeneratingAutocompleteObjectArray[];
  onSubmit: any;
}

function ucFirst(str: string): string {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
}

/**
 * Фильтр для таблицы алертов, (Journal)
 */

export const AlertsFilter: React.FC<IAlertsFilter> = ({
  setFilteringData,
  targetAssets,
  onSubmit,
}) => {
  const [isShowFilterContent, setIsShowFilterContent] =
    useState<boolean>(false);
  const [impactRate, setImpactRate] = useState([]);
  const [eventId, setEventId] = useState<string | null>(null);
  const [detectorId, setDetectorId] = useState<string | null>(null);
  const [vehicleModel, setVehicleModel] = useState<string | null>(null);
  const [vehicleVin, setVehicleVin] = useState<string | null>(null);

  useEffect(() => {
    setFilteringData({
      impactRates: impactRate,
    });
  }, [impactRate]);

  useEffect(() => {
    if (eventId?.length) {
      setFilteringData((prevState: any) => ({
        ...prevState,
        id: eventId,
      }));
    }

    if (detectorId?.length) {
      setFilteringData((prevState: any) => ({
        ...prevState,
        detectorId: parseInt(detectorId),
      }));
    }

    if (vehicleModel?.length) {
      setFilteringData((prevState: any) => ({
        ...prevState,
        vehicleModel,
      }));
    }

    if (vehicleVin?.length) {
      setFilteringData((prevState: any) => ({
        ...prevState,
        vehicleVin,
      }));
    }
  }, [eventId, detectorId, vehicleModel, vehicleVin]);

  return (
    <Box m="20px 0">
      <AlertsFilterHeader
        setIsShowContent={setIsShowFilterContent}
        isShowContent={isShowFilterContent}
        onSubmit={onSubmit}
      />
      <Stack flexDirection="row" alignItems="center">
        <Grid item sx={{ width: "100%" }}>
          {isShowFilterContent && (
            <Grid item mt="20px">
              <Stack flexDirection="row" justifyContent="space-between">
                <FormControl component="fieldset">
                  <Typography color="textSecondary">Type of signal</Typography>
                  <RadioGroup
                    aria-label="Type of signal"
                    name="radio-buttons-group"
                    defaultValue="All signals"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFilteringData((prevState: any) => ({
                        ...prevState,
                        type:
                          (event.target as HTMLInputElement).value ===
                          "Only alerts"
                            ? "alert"
                            : "event",
                      }))
                    }
                  >
                    {typeSignals.map((signal) => (
                      <FormControlLabel
                        value={signal}
                        label={signal}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <Typography color="textSecondary">Signals status</Typography>
                  <RadioGroup
                    aria-label="Signals status"
                    name="radio-buttons-group"
                    defaultValue="All status"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFilteringData((prevState: any) => ({
                        ...prevState,
                        isChecked:
                          (event.target as HTMLInputElement).value ===
                          "Checked",
                      }))
                    }
                  >
                    {statusSignals.map((signal) => (
                      <FormControlLabel
                        value={signal}
                        label={signal}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormGroup>
                  <Typography color="textSecondary">Impact rate</Typography>
                  {impacts.map((impact) => (
                    <FormControlLabel
                      style={{ margin: "5px 0" }}
                      value={impact}
                      control={
                        <Checkbox
                          onChange={(event) => {
                            setImpactRate((prevState: any) => {
                              prevState = [...prevState];
                              const value = ucFirst(event.target.value);
                              if (!prevState.includes(value)) {
                                prevState.push(ucFirst(event.target.value));
                                return prevState;
                              }
                              const iValue = prevState.indexOf(value);
                              if (iValue > -1) {
                                prevState.splice(iValue, 1);
                              }
                              return prevState;
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={<ImpactRate type={impact} />}
                    />
                  ))}
                  {/* <RadioGroup*/}
                  {/*  aria-label="Impact rate"*/}
                  {/*  name="radio-buttons-group"*/}
                  {/*  sx={{ "& label": { mt: "10px" } }}*/}
                  {/*  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>*/}
                  {/*    setFilteringData((prevState: any) => ({*/}
                  {/*      ...prevState,*/}
                  {/*      impactRate: (event.target as HTMLInputElement).value,*/}
                  {/*    }))*/}
                  {/*  }*/}
                  {/* >*/}
                  {/*  {impacts.map((impact) => (*/}
                  {/*    <FormControlLabel*/}
                  {/*      value={impact}*/}
                  {/*      control={<Radio />}*/}
                  {/*      label={<ImpactRate type={impact} />}*/}
                  {/*    />*/}
                  {/*  ))}*/}
                  {/* </RadioGroup>*/}
                </FormGroup>
                <FormControl>
                  <Typography color="textSecondary" mb="5px">
                    Target asset type
                  </Typography>
                  <CustomAutocomplete
                    fieldName="Target assets"
                    label="Target assets"
                    placeholder="Target assets"
                    option={targetAssets}
                    onChange={(event: any, value: any) => {
                      setFilteringData((prevState: any) => ({
                        ...prevState,
                        targetAssets: value.map((item: any) => item.title),
                      }));
                    }}
                  />
                </FormControl>
                <FormControl sx={{ "& input": { width: 300 } }}>
                  <Typography color="textSecondary" mb="5px">
                    Search by event ID
                  </Typography>
                  <TextField
                    id="event_id"
                    label="Event ID"
                    variant="outlined"
                    value={eventId}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setEventId((event.target as HTMLInputElement).value);
                    }}
                  />
                  <Typography color="textSecondary" m="30px 0 5px">
                    Search by detector ID
                  </Typography>
                  <TextField
                    id="detector_id"
                    label="Detector ID"
                    variant="outlined"
                    value={detectorId}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDetectorId((event.target as HTMLInputElement).value);
                    }}
                  />
                </FormControl>
                <FormControl sx={{ "& input": { width: 300 } }}>
                  <Typography color="textSecondary" mb="5px">
                    Search by model
                  </Typography>
                  <TextField
                    id="model"
                    label="Model"
                    variant="outlined"
                    value={vehicleModel}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setVehicleModel((event.target as HTMLInputElement).value);
                    }}
                  />
                  <Typography color="textSecondary" m="30px 0 5px">
                    Search by VIN
                  </Typography>
                  <TextField
                    id="vin"
                    label="VIN"
                    variant="outlined"
                    value={vehicleVin}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setVehicleVin((event.target as HTMLInputElement).value);
                    }}
                  />
                </FormControl>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};
