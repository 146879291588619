import React from "react";

import { IIconNavigationBar } from "../../../types";
import { getIconStyle } from "../../../utils";

/**
 * Иконка настроек. Принимает на вход:
 *
 * @param {string} className - Внешний класс для стилизации
 * @param {string} height - Высота
 * @param {string} width - Ширина
 * @param {string} fill - Цвет
 * @param {boolean} isDark - Стиль иконки темный/яркий
 * @returns {JSX.Element}
 */

export const Settings: React.FC<IIconNavigationBar> = ({
  fill = "white",
  width = "36",
  height = "36",
  className = "",
  isDark,
}) => {
  const iconStyle = getIconStyle(isDark);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        opacity="0.05"
        d="M29.25 36H6.75C3.02179 36 0 32.9782 0 29.25V6.75C0 3.02179 3.02179 0 6.75 0H29.25C32.9782 0 36 3.02179 36 6.75V29.25C36 32.9782 32.9782 36 29.25 36Z"
        fill={iconStyle.white}
      />
      <path
        d="M19 27V25H27V23H19V21H17V27H19ZM13 15V17H9V19H13V21H15V15H13ZM21 15H23V13H27V11H23V9H21V15Z"
        fill={iconStyle.orange}
      />
      <path d="M9 25V23H15V25H9Z" fill={iconStyle.white} />
      <path d="M9 13V11H19V13H9Z" fill={iconStyle.white} />
      <path d="M27 17V19H17V17H27Z" fill={iconStyle.white} />
    </svg>
  );
};
