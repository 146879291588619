import { Stack } from "@mui/material";
import React, { Dispatch } from "react";

import { CustomAutocomplete } from "../../../../components";
import { IGeneratingAutocompleteObjectArray } from "../../../../types";
import styles from "./_filter.module.scss";

export const Filters: React.FC<{
  vehiclesBrands: IGeneratingAutocompleteObjectArray[];
  vehiclesModels: IGeneratingAutocompleteObjectArray[];
  vehiclesVins: IGeneratingAutocompleteObjectArray[];
  setSelectedFilteringFields: Dispatch<any>;
}> = ({
  vehiclesBrands,
  vehiclesModels,
  vehiclesVins,
  setSelectedFilteringFields,
}) => {
  const onchangeFilterData = ({
    name,
    value,
  }: {
    name: string;
    value: any;
  }): void => {
    if (name === "brand") {
      setSelectedFilteringFields((prevState: any) => ({
        ...prevState,
        brands: value.map((brand: any) => brand.title),
      }));
    } else if (name === "model") {
      setSelectedFilteringFields((prevState: any) => ({
        ...prevState,
        models: value.map((model: any) => model.title),
      }));
    } else {
      setSelectedFilteringFields((prevState: any) => ({
        ...prevState,
        vins: value.map((vin: any) => vin.title),
      }));
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.content_header}>
        <div className={styles.content_body}>
          <Stack direction="row" spacing={4}>
            <CustomAutocomplete
              fieldName="brand"
              label="Brand"
              placeholder="Brand"
              option={vehiclesBrands}
              onChange={(event: any, value: any) =>
                onchangeFilterData({
                  name: "brand",
                  value,
                })
              }
            />
            <CustomAutocomplete
              fieldName="model"
              label="Model"
              placeholder="Model"
              option={vehiclesModels}
              onChange={(event: any, value: any) =>
                onchangeFilterData({
                  name: "model",
                  value,
                })
              }
            />
            <CustomAutocomplete
              fieldName="vin"
              label="VIN"
              placeholder="VIN"
              option={vehiclesVins}
              onChange={(event: any, value: any) =>
                onchangeFilterData({
                  name: "vin",
                  value,
                })
              }
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};
