import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { XYChart } from "../../../../../../components/molecules/Charts";
import { IAlertsTimeline } from "../../../../../../types";
import { useActionsHook, useTypedSelectorHook } from "../../../../../../utils";
import { CustomStack } from "./_styled.constants";

/**
 * Вкладка таймлайна за период дня Alerts Timeline - Day *
 */

export const TimelinePerDay: React.FC = () => {
  const { alertsTimeline } = useTypedSelectorHook(({ alerts }) => alerts);
  const { getAlertsTimelineActions } = useActionsHook();
  const [timelinePerDay, setTimelinePerDay] = useState<IAlertsTimeline[]>([]);

  useEffect(() => {
    getAlertsTimelineActions("day");
  }, []);

  useEffect(() => {
    if (alertsTimeline) {
      setTimelinePerDay(
        alertsTimeline.map((item) => {
          return {
            ...item,
          };
        })
      );
    }
  }, [alertsTimeline]);

  return (
    <CustomStack>
      <Typography component="span" color="primary.main">
        By quantity
      </Typography>
      <Typography component="label" color="primary.main">
        Time
      </Typography>
      <XYChart
        id="timelineDay"
        category="time"
        value="sum"
        data={timelinePerDay}
      />
    </CustomStack>
  );
};
