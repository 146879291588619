import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";

import { Footer, JournalDataGrid, TitleBar } from "../../components";
import { useActionsHook, useTypedSelectorHook } from "../../utils";

/**
 * Страница со списком алетов, (Journal)
 */

export const AlertsPage: React.FC = () => {
  const { getAlertsActions, getTargetAssetsActions } = useActionsHook();
  const { alerts, alertTargetAssets } = useTypedSelectorHook(
    ({ alerts }) => alerts
  );

  useEffect(() => {
    getAlertsActions();
    getTargetAssetsActions();
  }, []);

  if (!alerts) return null;

  return (
    <Container maxWidth={false}>
      <Grid item>
        <TitleBar title="Journal" />
        <JournalDataGrid
          alerts={alerts}
          alertTargetAssets={alertTargetAssets}
          alertsAction={getAlertsActions}
        />
        <Footer />
      </Grid>
    </Container>
  );
};
