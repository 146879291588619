import { createTheme, darken } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const defaultTheme = createTheme();

export const useStyles = makeStyles(
  () => {
    return {
      root: {
        "& .super-app-theme--false": {
          backgroundColor: "#A82121",
          "&:hover": {
            backgroundColor: darken("#861a1a", 0.2),
          },
        },
        "& .Mui-selected": {
          backgroundColor: "#6b1414 !important",
          backgroundImage: "none !important",
        },
      },
    };
  },
  { defaultTheme }
);
