import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import ShareIcon from "@mui/icons-material/Share";
import { Box, Button } from "@mui/material";
import React from "react";

/**
 * Футер - кнопки Export PDF и Send *
 */

export const Footer: React.FC = () => {
  return (
    <Box m="24px 0">
      <Button
        variant="outlined"
        startIcon={<PictureAsPdfRoundedIcon color="primary" />}
        size="large"
        sx={{ marginRight: "20px" }}
        disabled={true}
      >
        Export PDF
      </Button>
      <Button
        variant="outlined"
        startIcon={<ShareIcon color="primary" />}
        disabled={true}
        size="large"
      >
        Send a list
      </Button>
    </Box>
  );
};
