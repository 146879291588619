/**
 * Типы ключей объекта Demo Settings
 */

export interface IDemoSettings {
  alertDelay: string;
  eventDelay: string;
  timezone: string;
  isSimulation: boolean;
}

/**
 * Типы для экшена и редьюсера Settings
 */

export enum SettingsActionTypes {
  SET_DEMO_SETTINGS_SUCCESS = "SET_DEMO_SETTINGS_SUCCESS",
  SET_DEMO_SETTINGS_REQUEST = "SET_DEMO_SETTINGS_REQUEST",
  SET_ADD_DEMO_SETTINGS_REQUEST = "SET_ADD_DEMO_SETTINGS_REQUEST",
}

export interface ISetDemoSettings {
  type: SettingsActionTypes.SET_DEMO_SETTINGS_SUCCESS;
  payload: IDemoSettings[];
}

export interface ISetDemoSettingsRequest {
  type: SettingsActionTypes.SET_DEMO_SETTINGS_REQUEST;
  payload: boolean;
}

export interface ISetAddDemoSettingsRequest {
  type: SettingsActionTypes.SET_ADD_DEMO_SETTINGS_REQUEST;
  payload: boolean;
}

export type IActionSettings =
  | ISetDemoSettings
  | ISetDemoSettingsRequest
  | ISetAddDemoSettingsRequest;
