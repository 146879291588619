import { TabContext, TabPanel } from "@mui/lab";
import { Grid, Tab, Tabs } from "@mui/material";
import React from "react";

import { TitleBar } from "../../../../../components";
import { TimelineAll, TimelinePerDay, TimelinePerWeek } from "./fragments";

/**
 * Компонент Alerts timeline страницы  Dashboard *
 */

export const AlertsTimeline: React.FC = () => {
  const [timelineValue, setTimelineValue] =
    React.useState<string>("TimelineAll");

  const handleChangeTimeline = (
    event: React.SyntheticEvent,
    timelineValue: string
  ): void => {
    setTimelineValue(timelineValue);
  };

  return (
    <Grid item sx={{ height: "287px" }}>
      <TitleBar title="Alerts timeline" />
      <Grid item mt="15px">
        <TabContext value={timelineValue}>
          <Tabs
            variant="fullWidth"
            value={timelineValue}
            onChange={handleChangeTimeline}
          >
            <Tab value="TimelineAll" label="All" />
            <Tab value="TimelineDay" label="Day" />
            <Tab value="TimelineWeek" label="Week" />
          </Tabs>
          <TabPanel value="TimelineAll" sx={{ p: 0 }}>
            <TimelineAll />
          </TabPanel>
          <TabPanel value="TimelineDay" sx={{ p: 0 }}>
            <TimelinePerDay />
          </TabPanel>
          <TabPanel value="TimelineWeek" sx={{ p: 0 }}>
            <TimelinePerWeek />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};
