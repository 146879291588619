import {
  Button,
  Grid,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

import { useActionsHook, useTypedSelectorHook } from "../../../utils";
import {
  CustomizedBox,
  CustomizedLabel,
  CustomizedStack,
  Input,
} from "./_styled.constants";

/**
 * Вкладка настроек симуляции Demo Settings
 */

export const DemoSettings = () => {
  const { getDemoSettingsActions } = useActionsHook();
  const { setDemoSettingsActions } = useActionsHook();
  const { settingDemo } = useTypedSelectorHook(({ settings }) => settings);

  // const [value, setValue] = React.useState<Date | null>(
  //   new Date("2014-08-18T21:11:54")
  // );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [eventTime, setEventTime] = React.useState<string>("");
  const handleChangeEventTime = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEventTime(event.target.value);
  };

  const [alertTime, setAlertTime] = React.useState<string>("");
  const handleChangeAlertTime = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAlertTime(event.target.value);
  };
  const [timezone, setTimezone] = React.useState<string>("");

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    fetchData();
  };

  function fetchData() {
    setDemoSettingsActions(alertTime, eventTime, timezone, checked, () => {
      getDemoSettingsActions();
    });
  }

  const [checked, setChecked] = React.useState<boolean>(true);

  useEffect(() => {
    getDemoSettingsActions();
  }, []);

  useEffect(() => {
    if (settingDemo) {
      const state: any = settingDemo;
      setEventTime(state.eventDelay);
      setAlertTime(state.alertDelay);
      setChecked(state.isSimulation);
      setTimezone(state.timezone);
    }
  }, [settingDemo]);

  return (
    <Grid>
      <Typography variant="h5" m="20px">
        The page for configuring the frequency of displaying events in the log
      </Typography>
      <Grid item>
        <CustomizedStack>
          <CustomizedLabel variant="body1" color="textSecondary">
            Events log frequency
          </CustomizedLabel>
          <TextField
            id="time"
            label="Events log frequency"
            type="time"
            value={eventTime}
            onChange={handleChangeEventTime}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            sx={{
              width: 260,
            }}
          />
          <Button
            variant="contained"
            size="large"
            sx={{ width: "160px", marginLeft: "20px" }}
            onClick={fetchData}
          >
            Save
          </Button>
        </CustomizedStack>
        <CustomizedStack>
          <CustomizedLabel variant="body1" color="textSecondary">
            Alerts log frequency
          </CustomizedLabel>
          <TextField
            id="time"
            label="Alerts log frequency"
            type="time"
            value={alertTime}
            onChange={handleChangeAlertTime}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            sx={{
              width: 260,
            }}
          />
          <Button
            variant="contained"
            size="large"
            sx={{ width: "160px", marginLeft: "20px" }}
            onClick={fetchData}
          >
            Save
          </Button>
        </CustomizedStack>
        <CustomizedStack>
          <CustomizedLabel variant="body1" color="textSecondary">
            Simulation trigger
          </CustomizedLabel>
          <Switch
            checked={checked}
            onChange={handleChangeSwitch}
            inputProps={{ "aria-label": "controlled" }}
          />
        </CustomizedStack>
        <CustomizedStack>
          <CustomizedLabel variant="body1" color="textSecondary">
            Upload data
          </CustomizedLabel>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={(event) => {
                if (event.target.value) handleOpen();
              }}
            />
            <Button variant="contained" component="span">
              Upload
            </Button>
          </label>
          <Modal
            open={open}
            onClose={handleClose}
            aria-describedby="modal-modal-description"
          >
            <CustomizedBox>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Ok, the data upload.
              </Typography>
            </CustomizedBox>
          </Modal>
        </CustomizedStack>
      </Grid>
    </Grid>
  );
};
