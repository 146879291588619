import "@mui/lab/themeAugmentation";

import { createTheme } from "@mui/material/styles";
import {
  DataGridComponentNameToClassKey,
  DataGridComponents,
  DataGridComponentsPropsList,
} from "@mui/x-data-grid/themeAugmentation";

interface ITheme {
  components?:
    | { [key: string]: any }
    | DataGridComponentsPropsList
    | DataGridComponents
    | DataGridComponentNameToClassKey
    | undefined;
  palette?: { [key: string]: any };
}

/**
 * Кастомизация темы Material UI и его компонентов
 */

export const theme: ITheme = createTheme({
  components: {
    //  Стилизация таблицы и ее элементов //

    MuiTable: {
      styleOverrides: {
        root: {
          background: "transparent",

          ".Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.1) !important",
            boxShadow: "inset 0px -1px 0px rgba(224, 224, 224, 0.15)",
          },

          ".MuiCheckbox-root": {
            color: "#FFF",
          },
        },
      },
    },

    //  Стилизация DataGrid и ее элементов //

    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            background:
              "linear-gradient(90deg, rgba(136, 136, 136, 0.1) 0%, rgba(147, 147, 147, 0.1) 100.83%) !important",
          },

          "& .MuiDataGrid-checkboxInput": {
            color: "#FFF",
          },

          "& .MuiDataGrid-columnHeaderTitleContainer": {
            padding: 0,
          },

          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },

          "& .MuiDataGrid-columnHeaders *:focus-within, .MuiDataGrid-columnHeaders *:focus":
            {
              outline: "none !important",
            },

          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          ".MuiList-root": {
            height: "inherit",
          },
        },
      },
    },

    // Tabs //

    MuiTab: {
      styleOverrides: {
        root: {
          "&.MuiTab-root": {
            maxWidth: "100%",
            boxShadow: "inset 0px -.5px 0px rgba(255, 255, 255, 0.63)",
          },
          "&.MuiTabs-flexContainer": {
            maxWidth: "100%",
            display: "flex",
            justifyContent: "space-between",
          },

          "&.Mui-selected": {
            color: "#FFF",
            background:
              "linear-gradient(90deg, rgba(136, 136, 136, 0.1) 0%, rgba(147, 147, 147, 0.1) 100.83%)",
          },
        },
      },
    },

    // Button //

    MuiButton: {
      styleOverrides: {
        root: {
          color: "#FFF",
        },
      },
    },

    // Checkbox //

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#FFF",
        },
      },
    },

    // Radio //

    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#FFF",
        },
      },
    },

    // Alerts //

    MuiAlert: {
      styleOverrides: {
        root: {
          width: "170px",
          padding: "2px 15px",
        },
      },
    },

    //  Стилизация контейнера Paper //

    MuiPaper: {
      styleOverrides: {
        root: {
          background: "transparent",
          backgroundImage: "none",
        },
      },
    },
  },

  //  Colors //

  palette: {
    primary: {
      main: "#D27D00",
      light: "#FFB547",
      dark: "#C77700",
      contrastText: "#FFF",
    },
    secondary: {
      main: "rgba(255, 255, 255, 0)",
      light: "rgba(255, 255, 255, 0.01)",
      contrastText: "#FFF",
    },
    warning: {
      main: "#FF9800",
      light: "#E47F25",
      contrastText: "#FFF",
    },
    error: {
      main: "#F44336",
      dark: "#A82121",
      contrastText: "#FFF",
    },

    info: {
      main: "#2196F3",
      contrastText: "#FFF",
    },

    success: {
      main: "#4CAF50",
      contrastText: "#FFF",
    },

    mode: "dark",

    text: {
      primary: "#FFF",
      secondary: "#898989",
      disabled: "#3F3F3F",
    },
  },
});
