import { Box, Button, Container } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useActionsHook, useTypedSelectorHook } from "../../utils";
import { ActionsFields, Header, Logic, VehicleType } from "./fragments";

/**
 * Страница страница редактирования детекторов
 */

export const DetectorEditPage: React.FC = () => {
  const { id } = useParams();
  const { getDetectorAction, getVehiclesBrandAction, getModelsByBrandsAction } =
    useActionsHook();
  const { detector } = useTypedSelectorHook(({ detectors }) => detectors);
  const { vehiclesBrands, vehiclesModels } = useTypedSelectorHook(
    ({ vehicles }) => vehicles
  );
  const useFormMethods = useForm();

  useEffect(() => {
    if (id) {
      getDetectorAction(id);
    }

    getVehiclesBrandAction();
  }, []);

  // Используется для установки значений по умолчанию
  useEffect(() => {
    if (detector && vehiclesBrands) {
      useFormMethods.setValue("alertName", detector.alertName);
      useFormMethods.setValue("alertDescription", detector.alertDescription);
      useFormMethods.setValue("impactRate", detector.impactRate.toLowerCase());
    }
  }, [detector, vehiclesBrands]);

  const savingChangesEditing = (data: any): void => {
    console.log(data);
  };

  if (!detector && !vehiclesBrands) return null;

  return (
    <Container maxWidth={false}>
      <Header id={id} />
      <FormProvider {...useFormMethods}>
        <form onSubmit={useFormMethods.handleSubmit(savingChangesEditing)}>
          <ActionsFields />
          <VehicleType
            vehiclesBrands={vehiclesBrands}
            vehiclesModels={vehiclesModels ? vehiclesModels[0].model : []}
            getModelsByBrandsAction={getModelsByBrandsAction}
          />
          <Logic />
          <Box m="30px 0">
            <Button
              type="submit"
              children="Save"
              variant="contained"
              size="large"
              sx={{ width: "160px", marginRight: "20px" }}
            />
            <Button
              variant="outlined"
              size="large"
              sx={{ width: "160px" }}
              children="Cancel"
            />
          </Box>
        </form>
      </FormProvider>
    </Container>
  );
};
