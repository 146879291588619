import { Box, Paper } from "@mui/material";
import { styled } from "@mui/styles";

/**
 * Стили компонента Target Vehicle
 */

export const CustomizedBox = styled(Box)({
  minWidth: "438px",
  marginRight: "20px",
  border: "1px solid rgba(224, 224, 224, 0.1)",
});

export const CustomizedPaper = styled(Paper)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "482px",
  width: "100%",

  "& img": {
    width: "380px",
    height: "258px",
    display: "block",
    objectFit: "scale-down",
  },
});

export const CustomizedList = styled("li")({
  display: "flex",
  alignItems: "center",
  height: "52px",
  maxWidth: "100%",
  width: "100%",
  borderTop: "1px solid rgba(224, 224, 224, 0.1)",

  "& p:first-child": {
    width: 160,
    marginLeft: "15px",
  },

  "& p:last-child": {
    marginLeft: 15,
  },
});
