import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { ImpactRate } from "../../../components";
import { impacts } from "../../../components/molecules/Filter/_filter.constants";
import { Trigger } from "./_Trigger";

// Todo Yusuf: Описание
export const ActionsFields: React.FC = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <StyledContainer>
      <StyledBox>
        <Stack sx={{ width: 576 }}>
          <Typography variant="h5">Alert name and description</Typography>
          <TextField
            autoComplete="off"
            placeholder="Alert name"
            fullWidth
            label="Name *"
            error={!!errors.name}
            sx={{ margin: "28px 0 16px" }}
            {...register("alertName", { required: true })}
          />
          <TextField
            id="outlined-multiline-static"
            autoComplete="off"
            placeholder="Alert description"
            multiline
            variant="outlined"
            label="Description *"
            error={!!errors.description}
            rows={8}
            {...register("alertDescription", { required: true })}
          />
        </Stack>
        <FormControl component="fieldset">
          <Typography variant="h5">Impact rate</Typography>
          <Controller
            name="impactRate"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <RadioGroup
                aria-label="Impact rate"
                sx={{ mt: "20px", "& label": { mt: "10px" } }}
                {...field}
              >
                {impacts.map((impact) => (
                  <FormControlLabel
                    value={impact}
                    control={<Radio checked={impact === field.value} />}
                    label={<ImpactRate type={impact} />}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        <Trigger />
      </StyledBox>
    </StyledContainer>
  );
};

// Todo Yusuf: вынести в файл со стилями
const StyledContainer = styled("div")({
  padding: "24px 16px 0 16px",
});

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "22px",
  borderBottom: "1px solid rgba(133, 133, 133, 0.2)",
});
