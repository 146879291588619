import { Box, ListItem, Toolbar, Typography } from "@mui/material";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { itemNavigation } from "../../../../../constants";
import styles from "./_sidebar.module.scss";
import {
  CustomAlert,
  CustomizedDrawer,
  CustomizedList,
  CustomizedListItemText,
} from "./_styled.constants";

/**
 * Фрагмент боковой панели с навигацией
 *
 * @returns {React.FC}
 */

export const Sidebar: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <CustomizedDrawer variant="permanent">
      <Toolbar />
      <CustomizedList>
        {itemNavigation.map(({ path, label, icon }) => (
          <NavLink
            to={path}
            key={label}
            className={({ isActive }) =>
              isActive ? styles.nav_link_active : ""
            }
          >
            <ListItem button sx={{ borderRight: "2px solid transparent" }}>
              <CustomizedListItemText>
                {icon(pathname.startsWith(path))}
                {label}
              </CustomizedListItemText>
            </ListItem>
          </NavLink>
        ))}
      </CustomizedList>
      <Box mb="24px">
        <CustomAlert variant="outlined" severity="success">
          Connection
        </CustomAlert>
        <Typography sx={{ fontSize: "11px", marginLeft: "8px" }}>
          Last update {new Date().toLocaleDateString()}{" "}
          {new Date().toLocaleTimeString()}
        </Typography>
      </Box>
    </CustomizedDrawer>
  );
};
