import { Grid } from "@mui/material";
import { styled } from "@mui/styles";

/**
 * Стили Journal Event Page (Журнал/Событие)
 */

export const CustomizedGrid = styled(Grid)({
  width: "100%",
  border: "1px solid rgba(224, 224, 224, 0.1)",

  "& .MuiBox-root": {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 20px 20px",
    padding: "20px 0 15px",
    borderBottom: "1px solid rgba(133, 133, 133, 0.2)",
  },

  "& li": {
    display: "flex",
    padding: 0,

    "&:last-child": {
      marginTop: "4px",
    },

    "& h6": {
      width: 106,
    },
  },
});
