import { Checkbox } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

/**
 * Параметры заголовка таблицы Event Setting
 */

export const eventsHeadName: GridColDef[] = [
  { field: "id", hide: true },
  {
    field: "isActive",
    headerName: "Activity",
    renderCell: (params: GridRenderCellParams): JSX.Element => {
      return params.value ? <Checkbox defaultChecked /> : <Checkbox />;
    },
    minWidth: 20,
  },
  {
    field: "alertName",
    headerName: "Event",
    flex: 0.01,
  },
];
