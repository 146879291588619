import {
  Box,
  Button,
  FormGroup,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { IVehiclesBrand } from "../../../types";

interface IArraySelect {
  value: string;
  label: string;
}

// Функция генерации массива, для выпадающих списков
const generationDataForSelect = (array: string[]): IArraySelect[] => {
  return array.map((item: string) => ({
    value: item,
    label: item,
  }));
};

export const VehicleType: React.FC<{
  vehiclesBrands: IVehiclesBrand | null;
  vehiclesModels: string[] | null;
  getModelsByBrandsAction: any;
}> = ({ vehiclesBrands, vehiclesModels, getModelsByBrandsAction }) => {
  const { register } = useFormContext();
  const [selectBrands, setSelectBrands] = useState<IArraySelect[]>([]);
  const [selectModels, setSelectModels] = useState<IArraySelect[]>([]);

  useEffect(() => {
    if (vehiclesBrands && !selectBrands.length) {
      setSelectBrands((prevState) => [
        ...generationDataForSelect(vehiclesBrands),
      ]);
    }
  }, [vehiclesBrands]);

  useEffect(() => {
    if (vehiclesModels) {
      setSelectModels((prevState) => [
        ...generationDataForSelect(vehiclesModels),
      ]);
    }
  }, [vehiclesModels]);

  return (
    <Box sx={{ padding: "0 16px" }}>
      <Typography variant="h5" m="40px 0 15px">
        Vehicle type
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Specify vehicle
      </Typography>
      <FormGroup>
        <Stack flexDirection="row" alignItems="center" m="26px 0 35px">
          <Select
            sx={{ width: 320 }}
            {...register("vehicleBrands")}
            onChange={({ target: { value } }) => {
              getModelsByBrandsAction({
                brands: [value],
              });
            }}
          >
            {selectBrands.map((item) => (
              <MenuItem value={item.label}>{item.value}</MenuItem>
            ))}
          </Select>
          <Select
            sx={{ width: 320, m: "0 30px 0 20px" }}
            {...register("vehicleModels")}
          >
            {selectModels.map((item) => (
              <MenuItem value={item.label}>{item.value}</MenuItem>
            ))}
          </Select>
          <Button children="Add" variant="outlined" sx={{ width: 120 }} />
        </Stack>
      </FormGroup>
    </Box>
  );
};
