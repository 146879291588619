import { Box } from "@mui/material";
import { styled } from "@mui/styles";

export const continents = [
  { label: "Europe" },
  { label: "Asia" },
  { label: "Hanoi" },
  { label: "Africa" },
  { label: "America" },
];
export const cities = [
  { label: "Moscow" },
  { label: "Hanoi" },
  { label: "Tokyo" },
  { label: "Cairo" },
  { label: "Texas" },
];

export const rates = ["severe", "major", "moderate", "negligible", "no impact"];

export const labels = [
  "Select the incidents about which the Admin will receive an email notification",
  "Select the incidents about which the Hunter user will receive an email notification",
  "Select the incidents about which the Researcher user will receive an email notification",
  "Select the incidents about which the User will receive an email notification",
];

export const statusSignals = ["All status", "Checked", "Unchecked"];

// Todo Yusuf: это должно хранится в файле со стилями
export const CustomizedBox = styled(Box)({
  borderBottom: "1px solid rgba(133, 133, 133, 0.2)",
  paddingBottom: "20px",
});
