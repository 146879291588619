import { Typography } from "@mui/material";
import { styled } from "@mui/styles";

/**
 * Кастомизация шапка/header разделов (TitleBar)  *
 */

export const CustomizedTypography = styled(Typography)({
  boxShadow: "inset 0px -2px 0px #CD782C",
  padding: "8px 0 8px 20px",
  position: "relative",
  display: "flex",
  alignItems: "center",

  "&::before": {
    content: "''",
    backgroundColor: "#FF9800",
    width: "8px",
    height: "8px",
    display: "inline-block",
    margin: "0 12px 0 -20px",
  },

  "&.not_bordered": {
    boxShadow: "unset",
  },

  "& .MuiFormGroup-root": {
    position: "absolute",
    right: 0,
  },
});
