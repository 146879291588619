import GoogleMapReact from "google-map-react";
import React, { useState } from "react";

import iconMapMarker from "../../../../assets/images/icon-map-marker.svg";
import { carPosition, mapApiDefaultOption } from "./_googleMap.constants";

/**
 * Компонент/карта раздела Map of connected vehicles страницы  Dashboard *
 */

export const DashboardMap: React.FC<{
  showMachineInformation: () => void;
  setMapId: (id: number) => void;
}> = ({ showMachineInformation, setMapId }) => {
  const [mapApiOption, setMapApiOption] = useState(mapApiDefaultOption);

  const handleApiLoaded = (map: any, maps: any): void => {
    carPosition.map((position) => {
      const marker = new maps.Marker({
        position: position,
        icon: iconMapMarker,
        map,
      });

      marker.addListener("click", () => {
        showMachineInformation();
        setMapId(position.id);
      });
    });

    setMapApiOption((prevState) => {
      return {
        ...prevState,
        zoomControlOptions: {
          position: maps.ControlPosition.LEFT_BOTTOM,
        },
        fullscreenControl: false,
      };
    });
  };

  return (
    <div id="map">
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        bootstrapURLKeys={{ key: "AIzaSyBxstACj5pdsPVCD54FsqbbAwBvqwyrpAs" }}
        defaultCenter={mapApiDefaultOption.center}
        defaultZoom={mapApiDefaultOption.zoom}
        options={{
          ...mapApiOption,
        }}
      />
    </div>
  );
};
