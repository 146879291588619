import CheckIcon from "@mui/icons-material/Check";
import React, { Fragment } from "react";

import {
  CustomizedCheckIconBox,
  CustomizedWarningIcon,
} from "./_styled.constants";

/**
 * Элемент Status Icon (checked/new) таблицы *
 */

interface IStatusIcon {
  status: boolean;
}

export const StatusIcon: React.FC<IStatusIcon> = ({ status }) => {
  return status ? (
    <Fragment>
      <CustomizedCheckIconBox>
        <CheckIcon fontSize="small" />
      </CustomizedCheckIconBox>
      Checked
    </Fragment>
  ) : (
    <Fragment>
      <CustomizedWarningIcon viewBox="0 0 22 19" /> New
    </Fragment>
  );
};
