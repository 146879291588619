import { Dispatch } from "redux";

import {
  DetectorsActionTypes,
  IDetectorProps,
  IDetectorsProps,
  ISetDetector,
  ISetDetectorRequest,
  ISetDetectors,
  ISetDetectorsRequest,
} from "../../../types";
import { interceptor } from "../../../utils";

/**
 * Задает значение списка детекторов
 *
 * @param {IDetectorsProps[]} payload - список детекторов.
 * @returns {ISetDetectors}
 */

const setDetectors = (payload: IDetectorsProps[]): ISetDetectors => ({
  type: DetectorsActionTypes.GET_DETECTORS_SUCCESS,
  payload,
});

/**
 * Задает состояние запроса списка детекторов
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetDetectorsRequest}
 */

const setDetectorsRequest = (inRequest: boolean): ISetDetectorsRequest => ({
  type: DetectorsActionTypes.GET_DETECTORS_REQUEST,
  payload: inRequest,
});

/**
 *  Выполняет запрос на получение списка детекторов автомобилей
 */

export const getDetectorsAction = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setDetectorsRequest(true));
    try {
      const { status, data } = await interceptor.get("/detectors");

      if (status === 200) {
        dispatch(setDetectors(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setDetectorsRequest(false));
  };
};

/**
 * Задает значение информации по детектору
 *
 * @param {IDetectorProps[]} payload - информации по детектору.
 * @returns {ISetDetector}
 */

const setDetector = (payload: IDetectorProps): ISetDetector => ({
  type: DetectorsActionTypes.GET_DETECTOR_SUCCESS,
  payload,
});

/**
 * Задает состояние запроса за инофрмацией о детекторее
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetDetectorRequest}
 */

const setDetectorRequest = (inRequest: boolean): ISetDetectorRequest => ({
  type: DetectorsActionTypes.GET_DETECTOR_REQUEST,
  payload: inRequest,
});

/**
 *  Выполняет запрос на получение информации по детектору
 */

export const getDetectorAction = (detectorId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setDetectorRequest(true));
    try {
      const { status, data } = await interceptor.get(
        `/detectors/${detectorId}`
      );

      if (status === 200) {
        dispatch(setDetector(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setDetectorRequest(false));
  };
};
