import {
  AlertsActionTypes,
  IActionAlerts,
  IAlertProps,
  IAlertsProps,
  IAlertsStatistic,
  IAlertsTimeline,
  ITargetAssets,
} from "../../types";

interface IInitialState {
  alerts: IAlertsProps[] | null;
  alertsRequest: boolean;
  alert: IAlertProps | null;
  alertRequest: boolean;
  alertsStatistic: IAlertsStatistic[] | null;
  alertsStatisticRequest: boolean;
  alertsTimeline: IAlertsTimeline[] | null;
  alertsTimelineRequest: boolean;
  alertTargetAssets: ITargetAssets | null;
  alertsAssetsRequest: boolean;
}

const initialState: IInitialState = {
  alerts: null,
  alertsRequest: false,
  alert: null,
  alertRequest: false,
  alertsStatistic: null,
  alertsStatisticRequest: false,
  alertsTimeline: null,
  alertsTimelineRequest: false,
  alertTargetAssets: null,
  alertsAssetsRequest: false,
};

/**
 * Alerts редьюсер
 */

export const alertsReducer = (
  state = initialState,
  action: IActionAlerts
): IInitialState => {
  switch (action.type) {
    case AlertsActionTypes.SET_ALERTS_SUCCESS:
      return {
        ...state,
        alerts: action.payload,
      };
    case AlertsActionTypes.SET_ALERTS_REQUEST:
      return {
        ...state,
        alertsRequest: action.payload,
      };
    case AlertsActionTypes.SET_ALERT_SUCCESS:
      return {
        ...state,
        alert: action.payload,
      };
    case AlertsActionTypes.SET_ALERT_REQUEST:
      return {
        ...state,
        alertRequest: action.payload,
      };
    case AlertsActionTypes.SET_ALERTS_STATISTIC_SUCCESS:
      return {
        ...state,
        alertsStatistic: action.payload,
      };
    case AlertsActionTypes.SET_ALERTS_STATISTIC_REQUEST:
      return {
        ...state,
        alertsStatisticRequest: action.payload,
      };
    case AlertsActionTypes.SET_ALERTS_TIMELINE_SUCCESS:
      return {
        ...state,
        alertsTimeline: action.payload,
      };
    case AlertsActionTypes.SET_ALERTS_TIMELINE_REQUEST:
      return {
        ...state,
        alertsTimelineRequest: action.payload,
      };
    case AlertsActionTypes.SET_TARGET_ASSETS_SUCCESS:
      return {
        ...state,
        alertTargetAssets: action.payload,
      };
    case AlertsActionTypes.SET_TARGET_ASSETS_REQUEST:
      return {
        ...state,
        alertsAssetsRequest: action.payload,
      };
    default:
      return initialState;
  }
};
