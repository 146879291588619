import React, { ReactElement } from "react";

import {
  Analysis,
  Dashboard,
  Detector,
  Journal,
  Noticed,
  Settings,
  Users,
  Vehicles,
} from "../components/atoms";

/**
 * Константы пунктов меню (боковая панель)
 */

interface IItemNavigation {
  path: string;
  label: string;
  icon: (isDark: boolean) => ReactElement;
}

export const itemNavigation: IItemNavigation[] = [
  {
    path: "/dashboard",
    label: "Dashboard",
    icon: (isDark) => (isDark ? <Dashboard isDark={true} /> : <Dashboard />),
  },
  {
    path: "/journal",
    label: "Journal",
    icon: (isDark) => (isDark ? <Journal isDark={true} /> : <Journal />),
  },
  {
    path: "/vehicles",
    label: "Vehicles",
    icon: (isDark) => (isDark ? <Vehicles isDark={true} /> : <Vehicles />),
  },
  {
    path: "/analysis",
    label: "Analysis",
    icon: (isDark) => (isDark ? <Analysis isDark={true} /> : <Analysis />),
  },
  {
    path: "/noticed",
    label: "Noticed",
    icon: (isDark) => (isDark ? <Noticed isDark={true} /> : <Noticed />),
  },
  {
    path: "/detectors",
    label: "Detectors",
    icon: (isDark) => (isDark ? <Detector isDark={true} /> : <Detector />),
  },
  {
    path: "/users",
    label: "Users",
    icon: (isDark) => (isDark ? <Users isDark={true} /> : <Users />),
  },
  {
    path: "/settings",
    label: "Settings",
    icon: (isDark) => (isDark ? <Settings isDark={true} /> : <Settings />),
  },
];
