import { TabContext, TabPanel } from "@mui/lab";
import { Container, Tab, Tabs } from "@mui/material";
import React from "react";

import { TitleBar } from "../../components";
import { AppSettingsPage } from "./AppSettingsPage";
import { DemoSettings } from "./DemoSetting/_DemoSettings";
import { EventSettingsPage } from "./EventSettingsPage";

// Todo Yusuf: описание
export const SettingsPage: React.FC = () => {
  const [value, setValue] = React.useState("App settings");

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    setValue(newValue);
  };

  return (
    <Container maxWidth={false}>
      <TitleBar type="notBordered" title={`${value}`} />
      <TabContext value={value}>
        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
          <Tab value="App settings" label="App settings" />
          <Tab value="Events" label="Events" />
          <Tab value="Demo setting" label="Demo setting" />
          <Tab value="Certificates" label="Certificates" disabled />
          <Tab label="Sources" disabled />
        </Tabs>
        <TabPanel value="App settings" sx={{ p: 0 }}>
          <AppSettingsPage />
        </TabPanel>
        <TabPanel value="Events" sx={{ p: 0 }}>
          <EventSettingsPage />
        </TabPanel>
        <TabPanel value="Demo setting" sx={{ p: 0 }}>
          <DemoSettings />
        </TabPanel>
      </TabContext>
    </Container>
  );
};
