/**
 * Типы для экшена authUser и редьюсера User
 */

export enum AuthUserActionTypes {
  SET_TOKEN = "SET_TOKEN",
  SET_LOGIN_REQUEST = "SET_LOGIN_REQUEST",
}

export interface ISetToken {
  type: AuthUserActionTypes.SET_TOKEN;
  payload: string | null;
}

export interface ISetLoginRequest {
  type: AuthUserActionTypes.SET_LOGIN_REQUEST;
  payload: boolean;
}

export type IUserAction = ISetToken | ISetLoginRequest;
