/**
 * Данные по умолчанию, для API Google карты
 */

export const mapApiDefaultOption = {
  center: {
    lat: 21.030653,
    lng: 105.804817,
  },

  zoom: 13,

  styles: [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: 36,
        },
        {
          color: "#000000",
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#000000",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          saturation: "-100",
        },
        {
          lightness: "30",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          gamma: "0.00",
        },
        {
          lightness: "74",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "all",
      stylers: [
        {
          lightness: "3",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 17,
        },
      ],
    },
  ],
};

/**
 * Пример с данные о позиции маркеро (автомобилей) на карте
 */

export const carPosition = [
  {
    id: 0,
    lat: 21.03164174326126,
    lng: 105.8368546244201,
  },
  {
    id: 1,
    lat: 21.049860950601985,
    lng: 105.73975838251066,
  },
  {
    id: 2,
    lat: 21.01866727165557,
    lng: 105.83007400003045,
  },
  {
    id: 3,
    lat: 21.03027182194999,
    lng: 105.7882285184758,
  },
  {
    id: 4,
    lat: 21.04646831148573,
    lng: 105.79749823283127,
  },
  {
    id: 5,
    lat: 21.060525732836144,
    lng: 105.82515998727852,
  },
  {
    id: 6,
    lat: 21.00536025837281,
    lng: 105.80483027056307,
  },
  {
    id: 7,
    lat: 20.992419939969075,
    lng: 105.7612062976461,
  },
];

export const fakeDataMap = [
  {
    model: "VinFast LUX SA2.0",
    vin: "1HGBH41JXMN10988",
    image: 22,
  },
  {
    model: "Kia Rio",
    vin: "Z94C241AAJR015489",
    image: 10,
  },
  {
    model: "Toyota Corolla",
    vin: "VF3MJAHXVHS223237",
    image: 11,
  },
  {
    model: "VinFast President",
    vin: "1HGBH41JXMN10989",
    image: 23,
  },
  {
    model: "Ford Focus",
    vin: "X9FDXXEECDER81849",
    image: 7,
  },
  {
    model: "Honda Civic",
    vin: "Z6FDXXEECDFD88354",
    image: 8,
  },
  {
    model: "BMW M4",
    vin: "WP1ZZZ9YZLDA01712",
    image: 4,
  },
  {
    model: "Audi Q3",
    vin: "XTA219470J0211487",
    image: 2,
  },
];
