import {
  DetectorsActionTypes,
  IActionDetectors,
  IDetectorProps,
  IDetectorsProps,
} from "../../types";

interface IInitialState {
  detectors: IDetectorsProps[] | null;
  detectorsRequest: boolean;
  detector: IDetectorProps | null;
  detectorRequest: boolean;
}

const initialState: IInitialState = {
  detectors: null,
  detectorsRequest: false,
  detector: null,
  detectorRequest: false,
};

/**
 * Detectors редьюсер
 */

export const detectorsReducer = (
  state: IInitialState = initialState,
  action: IActionDetectors
): IInitialState => {
  switch (action.type) {
    case DetectorsActionTypes.GET_DETECTORS_SUCCESS:
      return {
        ...state,
        detectors: action.payload,
      };
    case DetectorsActionTypes.GET_DETECTORS_REQUEST:
      return {
        ...state,
        detectorsRequest: action.payload,
      };
    case DetectorsActionTypes.GET_DETECTOR_SUCCESS:
      return {
        ...state,
        detector: action.payload,
      };
    case DetectorsActionTypes.GET_DETECTOR_REQUEST:
      return {
        ...state,
        detectorRequest: action.payload,
      };
    default:
      return state;
  }
};
