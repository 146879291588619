import { Edit } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  ListItem,
  Step,
  Stepper,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { Fragment, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { ImpactRate, TitleBar } from "../../components";
import { useActionsHook, useTypedSelectorHook } from "../../utils";
import {
  CustomizedBox,
  CustomizedChip,
  CustomizedGrid,
  CustomizedList,
  CustomizedStepLabel,
  CustomizedTypography,
} from "./_styled.constants";

/**
 * Страница Detector (Detector page) *
 */

export const DetectorPage: React.FC = () => {
  const { id } = useParams();
  const { getDetectorAction } = useActionsHook();
  const { detector } = useTypedSelectorHook(({ detectors }) => detectors);

  useEffect(() => {
    if (id) {
      getDetectorAction(id);
    }
  }, []);

  if (!detector) return null;

  return (
    <Container maxWidth={false}>
      <Grid lg={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/detectors">
            Detectors
          </Link>
          <Typography color="text.primary">{detector.alertName}</Typography>
        </Breadcrumbs>
      </Grid>
      <TitleBar type="endAdornment" title={`Detector ID ${id}`}>
        <Link to={`/detectors/edit/${id}`} style={{ margin: "5px 0 5px auto" }}>
          <Button variant="outlined" startIcon={<Edit color="primary" />}>
            Edit
          </Button>
        </Link>
      </TitleBar>
      <Grid lg={12} p="20px">
        <CustomizedGrid>
          <Typography variant="h4">{detector.alertName}</Typography>
          <CustomizedTypography variant="body1" color="textSecondary">
            {detector.alertDescription}
          </CustomizedTypography>
          <CustomizedList>
            <ListItem>
              <Typography variant="h6" component="p" mb="25px">
                Impact rate
              </Typography>
              <ImpactRate type={detector.impactRate} />
            </ListItem>
            <ListItem>
              <Typography variant="h6">Last update</Typography>
              <Typography variant="body1">
                {format(new Date(detector.lastUpdated), "dd/MM/yyyy HH:mm:ss")}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="h6">Extension</Typography>
              <Typography variant="body1">{detector.trigger}</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="h6">Developer</Typography>
              <Typography variant="body1">{detector.developer}</Typography>
            </ListItem>
          </CustomizedList>
        </CustomizedGrid>
        <Typography variant="h6" m="24px 0">
          Logic
        </Typography>
        <CustomizedBox>
          {detector.logicRules.map((label, index) => (
            <Fragment>
              {index > 0 && (
                <Box m="24px 0">
                  <CustomizedChip
                    label="AND"
                    variant="filled"
                    color="primary"
                  />
                </Box>
              )}
              <Stepper nonLinear>
                <Step color="inherit">
                  <CustomizedStepLabel>{label.name}</CustomizedStepLabel>
                </Step>
                <Step color="inherit">
                  <CustomizedStepLabel>{label.state}</CustomizedStepLabel>
                </Step>
                <Step color="inherit">
                  <CustomizedStepLabel>{label.value}</CustomizedStepLabel>
                </Step>
              </Stepper>
            </Fragment>
          ))}
        </CustomizedBox>
      </Grid>
    </Container>
  );
};
