import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React from "react";

import { ICharts } from "./_charts.types";

/**
 * Компонент XY Chart
 * @param {id} - название ID тега куда выгрузится XY Chart.
 * @param {value} - значения объектов массива за счет чего суммируется статистика.
 * @param {category} - заголовки  объектов массива.
 * @param {data} - передаваемый массив.
 */

export const XYChart: React.FC<ICharts> = ({ id, value, category, data }) => {
  am4core.useTheme(am4themes_animated);
  const chart = am4core.create(`${id}`, am4charts.XYChart);
  chart.hiddenState.properties.opacity = 0;

  chart.data = data;

  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.dataFields.category = "time";
  categoryAxis.renderer.minGridDistance = 30;
  categoryAxis.fontSize = 11;
  categoryAxis.renderer.labels.template.dy = 5;
  chart.colors.list = [am4core.color("#D27D00")];
  chart.logo.disabled = true;

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.min = 0;
  valueAxis.renderer.minGridDistance = 25;
  valueAxis.renderer.baseGrid.disabled = true;

  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.categoryX = `${category}`;
  series.dataFields.valueY = `${value}`;
  series.columns.template.tooltipText = "{valueY.value}";
  series.columns.template.tooltipY = 0;
  series.columns.template.strokeOpacity = 0;

  return <div id={id} />;
};
