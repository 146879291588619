import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { apiBaseUrl } from '../../constants'

const interceptor = axios.create({
  baseURL: apiBaseUrl
});

interceptor.interceptors.request.use(
  (config: any): any => {
    const token: any = localStorage.getItem('token') || 'null';
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `${token}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

interceptor.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export { interceptor }