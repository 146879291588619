import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, Stack } from "@mui/material";
import React from "react";

interface IAlertsFilterHeader {
  onSubmit: any;
  setIsShowContent: any;
  isShowContent: boolean;
}

/**
 * Компонент верхней части фильтра алертов
 */

export const AlertsFilterHeader: React.FC<IAlertsFilterHeader> = ({
  setIsShowContent,
  isShowContent,
  onSubmit,
}) => {
  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [endDate, setEndDate] = useState<Date | null>(null);

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      mb="20px"
    >
      {/* <Stack flexDirection='row' alignItems='center'>*/}
      {/* <Typography color="textSecondary">Period</Typography>*/}
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>*/}
      {/*  <Box m="0 20px 0 40px">*/}
      {/*    <CustomDatePicker*/}
      {/*      setValue={setStartDate}*/}
      {/*      value={startDate}*/}
      {/*      label="Start date"*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*  <Box>*/}
      {/*    <CustomDatePicker*/}
      {/*      setValue={setEndDate}*/}
      {/*      value={endDate}*/}
      {/*      label="End date"*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/* </LocalizationProvider>*/}
      {/* </Stack>*/}
      <Button
        size="large"
        variant="contained"
        children="Apply"
        onClick={() => onSubmit({})}
        sx={{ width: 160, m: "0 10px 0 20px" }}
      />

      <Button
        size="large"
        variant="outlined"
        children="More Filters"
        startIcon={<FilterListIcon />}
        onClick={() => {
          setIsShowContent(!isShowContent);
        }}
      />
    </Stack>
  );
};
