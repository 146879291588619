import { DataGrid, GridRowsProp } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import { useStyles, vehiclesTableHeadName } from "../../../constants";
import { useTypedSelectorHook } from "../../../utils";

// Todo Yusuf: описание
export const VehiclesData: React.FC = () => {
  const [pageSize, setPageSize] = React.useState<number>(25);
  const classes = useStyles();
  const { vehiclesNoticed } = useTypedSelectorHook(({ vehicles }) => vehicles);
  const [vehiclesNoticedList, setVehiclesNoticedList] = useState<GridRowsProp>(
    []
  );

  useEffect(() => {
    if (vehiclesNoticed) setVehiclesNoticedList(vehiclesNoticed);
  }, [vehiclesNoticed]);

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      className={classes.root}
      hideFooterSelectedRowCount={false}
      rowsPerPageOptions={[25, 50, 100]}
      pageSize={pageSize}
      onPageSizeChange={(newPage) => setPageSize(newPage)}
      rows={vehiclesNoticedList}
      columns={vehiclesTableHeadName.map((column) => ({
        ...column,
        sortable: false,
      }))}
      checkboxSelection
      getRowClassName={(params) =>
        `super-app-theme--${params.getValue(params.id, "isChecked")}`
      }
    />
  );
};
