import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  JournalDataGrid,
  TargetVehicle,
  TitleBar,
  VehicleNetworkMap,
} from "../../components";
import { IAlertsProps } from "../../types";
import { useActionsHook, useTypedSelectorHook } from "../../utils";

export const VehiclePage: React.FC = () => {
  const [pageSize, setPageSize] = React.useState<number>(25);
  const { id } = useParams();
  const {
    getVehicleActions,
    getAlertsActions,
    getVehiclePhotoAction,
    getTargetAssetsActions,
  } = useActionsHook();
  const { vehicle, vehiclePhotoBlob } = useTypedSelectorHook(
    ({ vehicles }) => vehicles
  );
  const { alerts, alertTargetAssets } = useTypedSelectorHook(
    ({ alerts }) => alerts
  );
  const [vehiclePhoto, setVehiclePhoto] = useState<string | null>(null);
  const [alertData, setAlertsData] = useState<IAlertsProps[] | null>(null);

  useEffect(() => {
    if (id) {
      getVehicleActions(id);
      getVehiclePhotoAction(id);
      getTargetAssetsActions();
    }
  }, []);

  useEffect(() => {
    if (vehicle) {
      getAlertsActions({
        vehicleVin: vehicle.vin,
      });
    }
  }, [vehicle]);

  useEffect(() => {
    if (vehiclePhotoBlob) {
      setVehiclePhoto(vehiclePhotoBlob.img);
    }
  }, [vehiclePhotoBlob]);

  useEffect(() => {
    if (alerts) {
      setAlertsData(alerts);
    }
  }, [alerts]);

  if (!alertData) return null;

  return (
    <Container maxWidth={false}>
      <Grid item m="10px 0 20px">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Vehicle
          </Link>
          <Typography color="textPrimary">{vehicle?.vin}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid sx={{ display: "flex" }}>
        <TargetVehicle vehicleData={vehicle} vehicleImage={vehiclePhoto} />
        <VehicleNetworkMap />
      </Grid>
      <Grid mt="20px">
        <Grid item>
          <TitleBar title="Journal" />
        </Grid>
        <JournalDataGrid
          alerts={alertData}
          alertTargetAssets={alertTargetAssets}
          alertsAction={getAlertsActions}
          alertsActionFilterDefault={{
            vehicleVin: vehicle?.vin,
          }}
        />
      </Grid>
    </Container>
  );
};
