import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import { alertsTableHeadName, useStyles } from "../../../constants";
import { IAlertsProps } from "../../../types";
import { useActionsHook, useTypedSelectorHook } from "../../../utils";

// Todo Yusuf: описание
// Todo Yusuf: данные таблицы должна отрисовыватсья из API
export const EventsData: React.FC = () => {
  const [pageSize, setPageSize] = React.useState<number>(25);
  const classes = useStyles();
  const { getAlertsActions } = useActionsHook();
  const { alerts } = useTypedSelectorHook(({ alerts }) => alerts);
  const [alertsList, setAlertsList] = useState<IAlertsProps[]>([]);

  useEffect(() => {
    getAlertsActions();
  }, []);

  useEffect(() => {
    if (alerts) {
      setAlertsList(alerts);
    }
  }, [alerts]);

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      className={classes.root}
      hideFooterSelectedRowCount={false}
      rowsPerPageOptions={[25, 50, 100]}
      pageSize={pageSize}
      onPageSizeChange={(newPage) => setPageSize(newPage)}
      rows={alertsList}
      columns={alertsTableHeadName.map((column) => ({
        ...column,
        sortable: false,
      }))}
      checkboxSelection
      getRowClassName={(params) =>
        `super-app-theme--${params.getValue(params.id, "isChecked")}`
      }
    />
  );
};
