import { Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

import { IVehiclesProps } from "../../../types";
import { TitleBar } from "../TitleBar";
import {
  CustomizedBox,
  CustomizedList,
  CustomizedPaper,
} from "./_styled.constants";

interface ITargetVehicle {
  vehicleData: IVehiclesProps | null;
  vehicleImage?: string | null;
}

/**
 * Компонент Target Vehicle (выбранная машина) *
 * @param {vehicleData} - принимает данные машины уканные в IVehiclesProps.
 * @param {vehicleImage} - принимает строку/изображение машины (временно не обязательный параметр).
 **/

export const TargetVehicle: React.FC<ITargetVehicle> = ({
  vehicleData,
  vehicleImage,
}) => {
  return (
    <CustomizedBox>
      <TitleBar title="Target vehicle" />
      <CustomizedPaper>
        {vehicleImage && <img src={vehicleImage} alt="Car" />}
      </CustomizedPaper>
      <ul>
        <CustomizedList>
          <Typography color="primary" variant="body2">
            Model:
          </Typography>
          <Typography color="primary" variant="body2">
            {`${vehicleData?.brand} ${vehicleData?.model}`}
          </Typography>
        </CustomizedList>
        <CustomizedList>
          <Typography color="primary" variant="body2">
            VIN:
          </Typography>
          <Typography>{vehicleData?.vin}</Typography>
        </CustomizedList>
        <CustomizedList>
          <Typography color="primary" variant="body2">
            Last update:
          </Typography>
          <Typography>
            {vehicleData
              ? format(new Date(vehicleData.lastUpdated), "dd/MM/yyyy ")
              : ""}
          </Typography>
        </CustomizedList>
        <CustomizedList>
          <Typography color="primary">Number of incidens:</Typography>
          <Typography>{vehicleData?.alertsNumber}</Typography>
        </CustomizedList>
      </ul>
    </CustomizedBox>
  );
};
