import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import React from "react";

import { Filter, Footer } from "../../../components";
import { EventsData } from "./_EventsData";
import { VehiclesData } from "./_VehiclesData";

export const Tabs: React.FC = () => {
  const [value, setValue] = React.useState("2");

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="Tab">
            <Tab label="Event" value="1" sx={{ width: "50%" }} disabled />
            <Tab label="Vehicles" value="2" sx={{ width: "50%" }} />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <Grid item>
            <Filter />
            <EventsData />
            <Footer />
          </Grid>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0 }}>
          <Grid item>
            <br />
            <br />
            {/* <Filter />*/}
            <VehiclesData />
            <Footer />
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  );
};
