import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { PieChart } from "../../../components/molecules/Charts";
import { getAlertsStatisticActions } from "../../../store";
import { IAlertsStatistic } from "../../../types";
import { useActionsHook, useTypedSelectorHook } from "../../../utils";

/**
 * Вкладка Alerts страницы Analysis *
 */

export const Alerts: React.FC = () => {
  const { alertsStatistic } = useTypedSelectorHook(({ alerts }) => alerts);
  const { getAlertsStatisticActions } = useActionsHook();
  const [pieCharData, setPieCharData] = useState<IAlertsStatistic[]>([]);

  useEffect(() => {
    getAlertsStatisticActions("all");
  }, []);

  useEffect(() => {
    if (alertsStatistic) {
      setPieCharData(
        alertsStatistic.map((item) => {
          return {
            ...item,
          };
        })
      );
    }
  }, [alertsStatistic]);

  return (
    <Grid container spacing={2}>
      <Grid item width="100%">
        <Grid sx={{ width: 1000, margin: "50px auto 0" }}>
          <PieChart
            id="alertPieChart"
            value="value"
            category="category"
            data={pieCharData}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {/* <TitleBar title="Alerts timeline" />*/}
        <Grid item></Grid>
      </Grid>
    </Grid>
  );
};
