import React from "react";

import { IIconNavigationBar } from "../../../types";
import { getIconStyle } from "../../../utils";

/**
 * Иконка автомобиля. Принимает на вход:
 *
 * @param {string} className - Внешний класс для стилизации
 * @param {string} height - Высота
 * @param {string} width - Ширина
 * @param {string} fill - Цвет
 * @param {boolean} isDark - Стиль иконки темный/яркий
 * @returns {JSX.Element}
 */

export const Vehicles: React.FC<IIconNavigationBar> = ({
  fill = "white",
  width = "36",
  height = "36",
  className = "",
  isDark,
}) => {
  const iconStyle = getIconStyle(isDark);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        opacity="0.05"
        d="M29.25 36H6.75C3.02175 36 0 32.9782 0 29.25V6.75C0 3.02175 3.02175 0 6.75 0H29.25C32.9782 0 36 3.02175 36 6.75V29.25C36 32.9782 32.9782 36 29.25 36Z"
        fill={iconStyle.white}
      />
      <path
        d="M24.5171 20.205C24.0817 20.475 23.67 20.6854 23.3021 20.835C23.0467 20.9475 22.77 21.0004 22.5 21.0004C22.23 21.0004 21.9521 20.9475 21.6979 20.835C21.0454 20.565 20.25 20.115 19.4782 19.4996H12.3154L13.4257 16.9493C13.5461 16.6793 13.8161 16.4993 14.1086 16.4993H17.064C16.8536 16.0346 16.6894 15.5318 16.5994 14.9996H14.1075C13.2154 14.9996 12.4054 15.5318 12.0454 16.3496L10.5604 19.7618C10.5525 19.7764 10.5604 19.7921 10.5525 19.8146C9.63788 20.2804 9 21.2175 9 22.3121V25.1246C9 26.1596 9.84037 27 10.8754 27C11.9104 27 12.7507 26.1596 12.7507 25.1246V24.75H21.7507V25.1246C21.7496 26.1596 22.59 27 23.625 27C24.66 27 25.5004 26.1596 25.5004 25.1246V22.3121C25.5004 21.465 25.1179 20.7079 24.5171 20.205ZM13.5 23.2504H12.0004C11.5875 23.2504 11.25 22.9129 11.25 22.5C11.25 22.0871 11.5875 21.7496 12.0004 21.7496H13.5C13.9129 21.7496 14.2504 22.0871 14.2504 22.5C14.2504 22.9129 13.9129 23.2504 13.5 23.2504ZM22.5 23.2504H21.0004C20.5875 23.2504 20.25 22.9129 20.25 22.5C20.25 22.0871 20.5875 21.7496 21.0004 21.7496H22.5C22.9129 21.7496 23.2504 22.0871 23.2504 22.5C23.2504 22.9129 22.9129 23.2504 22.5 23.2504Z"
        fill={iconStyle.white}
      />
      <path
        d="M26.6186 10.3691L22.6811 9.03037C22.5641 8.98987 22.437 8.98987 22.3189 9.03037L18.3814 10.3691C18.153 10.4467 18 10.6605 18 10.9024V13.9162C18 17.595 22.1051 19.3815 22.2806 19.4569C22.4224 19.5165 22.5787 19.5165 22.7205 19.4569C22.8949 19.3815 27 17.5939 27 13.9151V10.9024C27 10.6605 26.847 10.4467 26.6186 10.3691ZM24.9649 13.2131L22.9028 15.8377C22.6395 16.173 22.1479 16.2214 21.825 15.9435L20.5121 14.8185C20.1971 14.5485 20.16 14.076 20.4311 13.761C20.7011 13.4471 21.1748 13.41 21.4875 13.68L22.2064 14.2954L23.7859 12.2861C24.0412 11.961 24.5126 11.9036 24.8389 12.1601C25.164 12.4166 25.2214 12.888 24.9649 13.2131Z"
        fill={iconStyle.orange}
      />
    </svg>
  );
};
