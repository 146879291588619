import { TabContext, TabPanel } from "@mui/lab";
import { Grid, Tab, Tabs } from "@mui/material";
import React from "react";

import { TitleBar } from "../../../../../components";
import { AlertsAll, AlertsPerDay, AlertsPerWeek } from "./fragments";

/**
 * Компонент Alerts Analytics страницы  Dashboard *
 */

export const AlertsAnalytics: React.FC = () => {
  const [alertValue, setAlertValue] = React.useState("All");

  const handleChangeAlert = (
    event: React.SyntheticEvent,
    alertValue: string
  ): void => {
    setAlertValue(alertValue);
  };

  return (
    <Grid item mb="15px" sx={{ height: "287px" }}>
      <TitleBar title="Alerts analytics" />
      <Grid item mb="15px">
        <TabContext value={alertValue}>
          <Tabs
            variant="fullWidth"
            value={alertValue}
            onChange={handleChangeAlert}
          >
            <Tab value="All" label="All" />
            <Tab value="Day" label="Day" />
            <Tab value="Week" label="Week" />
          </Tabs>
          <TabPanel value="All" sx={{ p: 0 }}>
            <AlertsAll />
          </TabPanel>
          <TabPanel value="Day" sx={{ p: 0 }}>
            <AlertsPerDay />
          </TabPanel>
          <TabPanel value="Week" sx={{ p: 0 }}>
            <AlertsPerWeek />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};
