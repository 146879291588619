import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

import { ElectedTableItemNoticed, StatusIcon } from "../components";
import { IVehiclesProps } from "../types";

/**
 * Пункты заголовков таблицы Vehicles
 */

export const vehiclesTableHeadName: GridColDef[] = [
  { field: "id", hide: true },
  {
    field: "is_noticed",
    headerName: "Noticed",
    minWidth: 70,
    renderCell: (params) => (
      <ElectedTableItemNoticed
        isNoticed={params.row.isNoticed}
        vehicleId={params.row.id}
      />
    ),
  },
  {
    field: "vin",
    headerName: "VIN",
    renderCell: (params: GridRenderCellParams<IVehiclesProps>) => (
      <Link to={`/vehicles/${params.row.id}`}>{params.row.vin}</Link>
    ),
    flex: 0.2,
  },
  { field: "brand", headerName: "Brand", flex: 0.2 },
  { field: "model", headerName: "Model", flex: 0.2 },
  {
    field: "lastUpdated",
    headerName: "Last update",
    renderCell: (params: GridRenderCellParams) =>
      format(new Date(params.value), "dd/MM/yyyy "),
    flex: 0.2,
  },
  { field: "alertsNumber", headerName: "Number of alerts", flex: 0.2 },
  {
    field: "isChecked",
    headerName: "Status",
    flex: 0.08,
    renderCell: (params: GridRenderCellParams) => (
      <StatusIcon status={params.value} />
    ),
  },
];
