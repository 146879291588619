import FilterListIcon from "@mui/icons-material/FilterList";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { ImpactRate } from "../_ImpactRate";
import {
  impacts,
  statusSignals,
  targetAssets,
  typeSignals,
} from "./_filter.constants";

/**
 * Филтр страницы Journal и Noticed *
 */

export const Filter: React.FC = () => {
  const now = new Date(Date.now());
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth() + 1, 0)
  );

  const handleChange = (newValue: Date | null): void => {
    setSelectedDate(newValue);
  };

  const [isShowFilterContent, setIsShowFilterContent] =
    useState<boolean>(false);

  const eventIsShowFilterContent = (): void => {
    return setIsShowFilterContent(!isShowFilterContent);
  };

  const [signalSearchValue, setSignalSearchValue] =
    useState<string>("All signals");

  const [statusSearchValue, setStatusSearchValue] =
    useState<string>("All status");

  const [selectedAssetsType, setSelectedAssetsType] = useState<
    { title: string }[]
  >([]);

  return (
    <Box m="20px 0">
      <Stack flexDirection="row" alignItems="center">
        <Grid item sx={{ width: "100%" }}>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mb="20px"
          >
            <Stack flexDirection="row" alignItems="center">
              <Typography color="textSecondary">Period</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box m="0 20px 0 40px">
                  <DesktopDatePicker
                    label="Start date"
                    inputFormat="dd/mm/yyyy"
                    value={selectedDate}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
                <Box>
                  <DesktopDatePicker
                    label="End date"
                    inputFormat="dd/mm/yyyy"
                    value={selectedDate}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ width: 160, m: "0 10px 0 20px" }}
                >
                  Apply
                </Button>
                <Button variant="outlined" size="large" sx={{ width: 160 }}>
                  Reset
                </Button>
              </LocalizationProvider>
            </Stack>
            <Button
              size="large"
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={() => eventIsShowFilterContent()}
            >
              More Filters
            </Button>
          </Stack>
          <Stack flexDirection="row" alignItems="center" sx={{ mt: "20px" }}>
            <Typography color="textSecondary" mr="40px">
              Filters
            </Typography>
            <Box>
              <Chip
                sx={{ mr: "10px" }}
                label={signalSearchValue}
                variant="outlined"
              />
              <Chip label={statusSearchValue} variant="outlined" />
            </Box>
          </Stack>
          {isShowFilterContent && (
            <Grid item mt="20px">
              <Stack flexDirection="row" justifyContent="space-between">
                <FormControl component="fieldset">
                  <Typography color="textSecondary">Type of signal</Typography>
                  <RadioGroup
                    aria-label="Type of signal"
                    name="radio-buttons-group"
                    defaultValue={signalSearchValue}
                  >
                    {typeSignals.map((signal) => (
                      <FormControlLabel
                        value={signal}
                        control={<Radio />}
                        label={signal}
                        onClick={() => setSignalSearchValue(`${signal}`)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <Typography color="textSecondary">Signals status</Typography>
                  <RadioGroup
                    aria-label="Signals status"
                    name="radio-buttons-group"
                    defaultValue={statusSearchValue}
                  >
                    {statusSignals.map((signal) => (
                      <FormControlLabel
                        value={signal}
                        control={<Radio />}
                        label={signal}
                        onClick={() => setStatusSearchValue(`${signal}`)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <Typography color="textSecondary">Impact rate</Typography>
                  <RadioGroup
                    aria-label="Impact rate"
                    name="radio-buttons-group"
                    sx={{ "& label": { mt: "10px" } }}
                  >
                    {impacts.map((impact) => (
                      <FormControlLabel
                        value={impact}
                        control={<Radio />}
                        label={<ImpactRate type={impact} />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <Typography color="textSecondary" mb="5px">
                    Target asset type
                  </Typography>
                  <Autocomplete
                    multiple
                    id="Target assets type"
                    options={targetAssets}
                    isOptionEqualToValue={(option, value) =>
                      option.title === value.title
                    }
                    onChange={(event, value) => setSelectedAssetsType(value)}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    sx={{ width: 320 }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.title}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Target assets type" />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ "& input": { width: 300 } }}>
                  <Typography color="textSecondary" mb="5px">
                    Search by event ID
                  </Typography>
                  <TextField
                    id="event_id"
                    label="Event ID"
                    variant="outlined"
                  />
                  <Typography color="textSecondary" m="30px 0 5px">
                    Search by detector ID
                  </Typography>
                  <TextField
                    id="detector_id"
                    label="Detector ID"
                    variant="outlined"
                  />
                </FormControl>
                <FormControl sx={{ "& input": { width: 300 } }}>
                  <Typography color="textSecondary" mb="5px">
                    Search by model
                  </Typography>
                  <TextField id="model" label="Model" variant="outlined" />
                  <Typography color="textSecondary" m="30px 0 5px">
                    Search by VIN
                  </Typography>
                  <TextField id="vin" label="VIN" variant="outlined" />
                </FormControl>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};
