/**
 * Типы ключей объекта Events
 */

export interface IEvents {
  id: number;
  isActive: boolean;
  alertName: string;
}

/**
 * Типы ключей объекта EventStatistic
 */

export interface IEventStatistic {
  category: string;
  value: number;
}

/**
 * Типы ключей объекта Add Event
 */

export interface IAddEvent {
  alertName: string;
  targetAsset: string;
}

/**
 * Типы для экшена и редьюсера Events и Event Statistic
 */

export enum EventsActionTypes {
  GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS",
  GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST",
  GET_EVENT_STATISTIC = "GET_EVENT_STATISTIC",
  GET_EVENT_STATISTIC_REQUEST = "GET_EVENT_STATISTIC_REQUEST",
  SET_ADD_EVENT_REQUEST = "SET_ADD_EVENT_REQUEST",
}

export interface ISetEvents {
  type: EventsActionTypes.GET_EVENTS_SUCCESS;
  payload: IEvents[];
}

export interface ISetEventsRequest {
  type: EventsActionTypes.GET_EVENTS_REQUEST;
  payload: boolean;
}

export interface ISetEventStatistic {
  type: EventsActionTypes.GET_EVENT_STATISTIC;
  payload: IEventStatistic[];
}

export interface ISetEventStatisticRequest {
  type: EventsActionTypes.GET_EVENT_STATISTIC_REQUEST;
  payload: boolean;
}

export interface ISetAddEventRequest {
  type: EventsActionTypes.SET_ADD_EVENT_REQUEST;
  payload: boolean;
}

export type IActionEvents = ISetEvents | ISetEventStatistic;
