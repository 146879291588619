import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { IconButton } from "@mui/material";
import React from "react";

import { useActionsHook } from "../../utils";

/**
 * Компонент таблицы пункта Noticed, для отображения статуса (Добавлен в Noticed или нет)
 */

// Todo: Придумать более понятное название, добавить правельный тип
export const ElectedTableItemNoticed: React.FC<{
  isNoticed: boolean;
  vehicleId: string;
}> = ({ isNoticed, vehicleId }) => {
  const {
    addVehicleNoticedAction,
    getVehiclesNoticedAction,
    getVehiclesActions,
  } = useActionsHook();

  return (
    <IconButton
      aria-label="upload picture"
      component="span"
      sx={{ ml: "4px" }}
      onClick={(event: { stopPropagation: () => void }) => {
        event.stopPropagation();
        addVehicleNoticedAction(vehicleId, () => {
          getVehiclesNoticedAction();
          getVehiclesActions();
        });
      }}
    >
      {isNoticed ? <StarIcon color="primary" /> : <StarOutlineIcon />}
    </IconButton>
  );
};
