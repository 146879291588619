import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";

export const CustomAutocomplete: React.FC<{
  fieldName: string;
  label: string;
  placeholder: string;
  option: { title: string }[];
  onChange: any;
}> = ({ fieldName, label, placeholder, option, onChange }) => (
  <Autocomplete
    multiple
    id={fieldName}
    sx={{ width: 320 }}
    filterSelectedOptions
    options={option}
    getOptionLabel={(option) => option.title}
    isOptionEqualToValue={(option, value) => option.title === value.title}
    onChange={onChange}
    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <Chip
          variant="outlined"
          label={option.title}
          {...getTagProps({ index })}
        />
      ))
    }
    renderInput={(params) => (
      <TextField {...params} label={label} placeholder={placeholder} />
    )}
  />
);
