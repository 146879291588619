import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { Fragment, useState } from "react";

import { alertsTableHeadName, useStyles } from "../../../../constants";
import { IAlertsProps, ITargetAssets } from "../../../../types";
import { generatingAutocompleteObjectArray } from "../../../../utils";
import { AlertsFilter } from "./_Filter";

interface IJournalDataGrid {
  alerts: IAlertsProps[];
  alertTargetAssets: ITargetAssets | null;
  alertsAction: any;
  alertsActionFilterDefault?: object;
}

/**
 * Таблица для отрисовки алертов
 */

export const JournalDataGrid: React.FC<IJournalDataGrid> = ({
  alerts,
  alertTargetAssets,
  alertsActionFilterDefault = {},
  alertsAction,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = React.useState<number>(25);

  const [filteringData, setFilteringData] = useState<object>({});

  const filteringEvent = (): void => {
    if (Object.keys(filteringData).length === 0) {
      alertsAction(alertsActionFilterDefault);
    }

    alertsAction({ ...filteringData, ...alertsActionFilterDefault });
    setFilteringData({});
  };

  return (
    <Fragment>
      <AlertsFilter
        setFilteringData={setFilteringData}
        targetAssets={generatingAutocompleteObjectArray(alertTargetAssets)}
        onSubmit={filteringEvent}
      />
      <DataGrid
        autoHeight
        disableColumnMenu
        className={classes.root}
        hideFooterSelectedRowCount={false}
        rowsPerPageOptions={[25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        rows={alerts}
        columns={alertsTableHeadName.map((column) => ({
          ...column,
          sortable: false,
        }))}
        checkboxSelection
        getRowClassName={(params) =>
          `super-app-theme--${params.getValue(params.id, "isChecked")}`
        }
      />
    </Fragment>
  );
};
