import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

import { ImpactRate, StatusIcon } from "../components";

/**
 * Пункты заголовков таблицы Alerts
 */

export const alertsTableHeadName: GridColDef[] = [
  {
    field: "isNoticed",
    headerName: "Noticed",
    minWidth: 70,
    renderCell: () => (
      <IconButton sx={{ ml: "4px" }}>
        <StarOutlineIcon />
      </IconButton>
    ),
    flex: 0.02,
  },
  {
    field: "dateCreated",
    headerName: "Time",
    renderCell: (params: GridRenderCellParams) =>
      format(new Date(params.value), "HH:mm:ss"),
    flex: 0.2,
  },
  { field: "id", headerName: "ID", flex: 0.06 },
  {
    field: "detectorId",
    headerName: "Detector ID",
    flex: 0.1,
  },
  {
    field: "alertName",
    headerName: "Name",
    renderCell: (params: GridRenderCellParams) => (
      <Link to={`/journal/${params.row.id}`}>{params.row.alertName}</Link>
    ),
    flex: 0.3,
  },
  {
    field: "alertDescription",
    headerName: "Description",
    flex: 0.2,
  },
  {
    field: "vehicleVin",
    headerName: "VIN",
    flex: 0.3,
  },
  {
    field: "vehicleModel",
    headerName: "Model",
    flex: 0.1,
    renderCell: (params: GridRenderCellParams): string => {
      return `${params.row.vehicleBrand} ${params.value}`;
    },
  },
  { field: "targetAssets", headerName: "Target asset", flex: 0.1 },
  {
    field: "impactRate",
    headerName: "Impact rate",
    renderCell: (params: GridRenderCellParams): JSX.Element | string => {
      return params.value ? <ImpactRate type={params.value} /> : "";
    },
    flex: 0.4,
  },
  {
    field: "dateUpdated",
    headerName: "Last update",
    renderCell: (params: GridRenderCellParams) =>
      format(new Date(params.value), "dd/MM/yyyy "),
    flex: 0.2,
  },
  {
    field: "isChecked",
    headerName: "Status",
    flex: 0.15,
    renderCell: (params: GridRenderCellParams) => (
      <StatusIcon status={params.value} />
    ),
  },
];

/**
 * Массив данных для отображения таблицы Journals
 */

export const journalData = [
  {
    id: 4,
    type: "alert",
    date_created: "2020-09-23T20:00:03.174Z",
    date_updated: "2020-09-23T20:00:03.174Z",
    detector_id: 1,
    alert_name: "Attempts to hack the system",
    alert_description: null,
    vehicle_vin: "XTA219470J0598452",
    vehicle_model: "Audi A3",
    target_assets: ["ips", "ids"],
    impact_rate: "severe",
    is_checked: false,
    is_noticed: false,
  },
  {
    id: 3,
    type: "alert",
    date_created: "2020-09-22T20:00:03.174Z",
    date_updated: "2020-09-22T21:00:03.174Z",
    detector_id: 3,
    alert_name: "Failure of telemetry settings",
    alert_description: null,
    vehicle_vin: "XTA219470J0598452",
    vehicle_model: "Audi A3",
    target_assets: ["tele"],
    impact_rate: "moderate",
    is_checked: true,
    is_noticed: false,
  },
  {
    id: 2,
    type: "alert",
    date_created: "2020-09-21T08:00:03.174Z",
    date_updated: "2020-09-21T09:37:55.484Z",
    detector_id: 5,
    alert_name: "Alarm notification",
    alert_description: null,
    vehicle_vin: "WP1ZZZ9YZLDA01136",
    vehicle_model: "BMW I8",
    target_assets: ["alarm"],
    impact_rate: "major",
    is_checked: true,
    is_noticed: false,
  },
  {
    id: 1,
    type: "alert",
    date_created: "2020-09-20T20:00:03.174Z",
    date_updated: "2020-09-20T20:00:03.174Z",
    detector_id: 4,
    alert_name: "Failed update settings",
    alert_description: null,
    vehicle_vin: "XTA219470J0598452",
    vehicle_model: "Audi A3",
    target_assets: ["fotasota"],
    impact_rate: "negligible",
    is_checked: false,
    is_noticed: false,
  },
  {
    id: 0,
    type: "alert",
    date_created: "2020-09-20T20:00:03.174Z",
    date_updated: "2020-09-20T20:00:03.174Z",
    detector_id: 4,
    alert_name: "Failed update settings",
    alert_description: null,
    vehicle_vin: "XTA219470J0598452",
    vehicle_model: "Audi A3",
    target_assets: ["fotasota"],
    impact_rate: "no impact",
    is_checked: false,
    is_noticed: false,
  },
];
