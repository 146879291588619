import React, { useEffect, useState } from "react";

import { PieChart } from "../../../../../../components/molecules/Charts";
import { IAlertsStatistic } from "../../../../../../types";
import { useActionsHook, useTypedSelectorHook } from "../../../../../../utils";

/**
 * Вкладка алертов в течение недели раздела Alerts Analytics - Week *
 */

export const AlertsPerWeek: React.FC = () => {
  const { alertsStatistic } = useTypedSelectorHook(({ alerts }) => alerts);
  const { getAlertsStatisticActions } = useActionsHook();
  const [pieChartDay, setPieChartDay] = useState<IAlertsStatistic[]>([]);

  useEffect(() => {
    getAlertsStatisticActions("week");
  }, []);

  useEffect(() => {
    if (alertsStatistic) {
      setPieChartDay(
        alertsStatistic.map((item) => {
          return {
            ...item,
          };
        })
      );
    }
  }, [alertsStatistic]);

  return (
    <PieChart
      id="dashboardPieChart"
      value="value"
      category="category"
      data={
        pieChartDay.length
          ? pieChartDay
          : [
              {
                category: "Negligible",
                value: 2,
              },
              {
                category: "Major",
                value: 23,
              },
              {
                category: "Severe",
                value: 12,
              },
            ]
      }
      width={200}
    />
  );
};
