import { Container, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import { Footer, TitleBar } from "../../components";
import { useStyles, vehiclesTableHeadName } from "../../constants";
import {
  generatingAutocompleteObjectArray,
  useActionsHook,
  useTypedSelectorHook,
} from "../../utils";
import { Filters } from "./fragments";

export const VehiclesPage: React.FC = () => {
  const classes = useStyles();
  const [pageSize, setPageSize] = React.useState<number>(25);
  const {
    getVehiclesActions,
    getVehiclesBrandAction,
    getModelsByBrandsAction,
    getVinsByModels,
  } = useActionsHook();
  const { vehicles, vehiclesBrands, vehiclesModels, vehiclesVins } =
    useTypedSelectorHook(({ vehicles }) => vehicles);
  const [selectedFilteringFields, setSelectedFilteringFields] = React.useState<{
    brands: string[];
    models: string[];
    vins: string[];
  }>({ brands: [], models: [], vins: [] });
  const [foundModels, setFoundModels] = useState<string[]>([]);

  useEffect(() => {
    getVehiclesActions();
    getVehiclesBrandAction();
  }, []);

  useEffect(() => {
    if (vehiclesModels) {
      getVinsByModels({ vehicleModels: vehiclesModels });

      const models = vehiclesModels?.map(({ model }) => model);
      models.map((model, index) => setFoundModels([model[index]]));
    }
  }, [vehiclesModels]);

  useEffect(() => {
    const brands = selectedFilteringFields.brands;
    const models = selectedFilteringFields.models;
    const data: { vehicleModels: any[] } = { vehicleModels: [] };

    brands.map((brand) => {
      data.vehicleModels.push({
        brand: brand,
        model: models,
      });
    });

    if (brands.length) {
      getModelsByBrandsAction({ brands });
      getVehiclesActions(data);
    }

    if (!brands.length && !models.length) {
      getVehiclesActions();
    }
  }, [selectedFilteringFields]);

  if (!vehicles) return null;

  return (
    <Container maxWidth={false}>
      <Grid item>
        <TitleBar title="Vehicles" />
      </Grid>
      <Grid item>
        <Filters
          vehiclesBrands={generatingAutocompleteObjectArray(vehiclesBrands)}
          vehiclesModels={generatingAutocompleteObjectArray(foundModels)}
          vehiclesVins={generatingAutocompleteObjectArray(
            vehiclesVins ? vehiclesVins : null
          )}
          setSelectedFilteringFields={setSelectedFilteringFields}
        />
        <DataGrid
          autoHeight
          disableColumnMenu
          className={classes.root}
          hideFooterSelectedRowCount={false}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          rows={vehicles}
          columns={vehiclesTableHeadName.map((column) => ({
            ...column,
            sortable: false,
          }))}
          checkboxSelection
          getRowClassName={(params) =>
            `super-app-theme--${params.getValue(params.id, "isChecked")}`
          }
        />
      </Grid>
      <Footer />
    </Container>
  );
};
