import React from "react";

import { IIconNavigationBar } from "../../../types";
import { getIconStyle } from "../../../utils";

/**
 * Иконка дашборд. Принимает на вход:
 *
 * @param {string} className - Внешний класс для стилизации
 * @param {string} height - Высота
 * @param {string} width - Ширина
 * @param {string} fill - Цвет
 * @param {boolean} isDark - Стиль иконки темный/яркий
 * @returns {JSX.Element}
 */

export const Dashboard: React.FC<IIconNavigationBar> = ({
  fill = "white",
  width = "36",
  height = "36",
  className = "",
  isDark,
}) => {
  const iconStyle = getIconStyle(isDark);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        opacity="0.05"
        d="M29.25 36H6.75C3.02179 36 0 32.9782 0 29.25V6.75C0 3.02179 3.02179 0 6.75 0H29.25C32.9782 0 36 3.02179 36 6.75V29.25C36 32.9782 32.9782 36 29.25 36Z"
        fill={iconStyle.white}
      />
      <path
        d="M15.9379 9H10.3129C9.58832 9 9 9.58832 9 10.3129V13.6879C9 14.4113 9.58832 15.0007 10.3129 15.0007H15.9379C16.6613 15.0007 17.2507 14.4124 17.2507 13.6879V10.3129C17.2496 9.58832 16.6613 9 15.9379 9Z"
        fill={iconStyle.white}
      />
      <path
        d="M15.9379 16.5004H10.3129C9.58832 16.5004 9 17.0887 9 17.8121V25.6871C9 26.4117 9.58832 27 10.3129 27H15.9379C16.6613 27 17.2507 26.4117 17.2507 25.6871V17.8121C17.2496 17.0887 16.6613 16.5004 15.9379 16.5004Z"
        fill={iconStyle.orange}
      />
      <path
        d="M25.6879 21.0004H20.0629C19.3394 21.0004 18.75 21.5887 18.75 22.3132V25.6882C18.75 26.4117 19.3383 27.0011 20.0629 27.0011H25.6879C26.4124 27 27.0007 26.4117 27.0007 25.6871V22.3121C27.0007 21.5887 26.4124 21.0004 25.6879 21.0004Z"
        fill={iconStyle.white}
      />
      <path
        d="M25.6879 9H20.0629C19.3394 9 18.75 9.58832 18.75 10.3129V18.1879C18.75 18.9113 19.3383 19.5007 20.0629 19.5007H25.6879C26.4113 19.5007 27.0007 18.9124 27.0007 18.1879V10.3129C27.0007 9.58832 26.4124 9 25.6879 9Z"
        fill={iconStyle.orange}
      />
    </svg>
  );
};
