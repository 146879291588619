import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";

export const CustomizedStack = styled(Stack)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: "20px",
  margin: "20px 0",
});

export const CustomizedLabel = styled(Typography)({
  marginRight: "20px",
  width: 160,
});

export const CustomizedBox = styled(Box)({
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "background.paper",
  border: "2px solid #000",
  boxShadow: "24px",
  padding: "40px",
});

export const Input = styled("input")({
  display: "none",
});
