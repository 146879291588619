import { Dispatch } from "redux";

import {
  EventsActionTypes,
  IEvents,
  IEventStatistic,
  ISetAddEventRequest,
  ISetEvents,
  ISetEventsRequest,
  ISetEventStatistic,
  ISetEventStatisticRequest,
} from "../../../types";
import { interceptor } from "../../../utils";

/**
 * Задает значение списка событии/Events
 *
 * @param {IEvents[]} payload - список событии/Events.
 * @returns {ISetEvents}
 */

const setEvents = (payload: IEvents[]): ISetEvents => ({
  type: EventsActionTypes.GET_EVENTS_SUCCESS,
  payload,
});

/**
 * Задает состояние запроса списка событии/Events
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetDetectorsRequest}
 */

const setEventsRequest = (inRequest: boolean): ISetEventsRequest => ({
  type: EventsActionTypes.GET_EVENTS_REQUEST,
  payload: inRequest,
});

/**
 *  Выполняет запрос на получение списка событии/Events
 */

export const getEventsActions = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setEventsRequest(true));
    try {
      const { status, data } = await interceptor.get("/events");

      if (status === 200) {
        dispatch(setEvents(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setEventsRequest(false));
  };
};

/**
 * Задает значение списка событии/Events
 *
 * @param {IEventStatistic[]} payload - список статистики событии/Events Statistic.
 * @returns {ISetEventStatistic}
 */

const setEventStatistic = (payload: IEventStatistic[]): ISetEventStatistic => ({
  type: EventsActionTypes.GET_EVENT_STATISTIC,
  payload,
});

/**
 * Задает состояние запроса список статистики событии/Events Statistic
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetDetectorsRequest}
 */

const setEventStatisticRequest = (
  inRequest: boolean
): ISetEventStatisticRequest => ({
  type: EventsActionTypes.GET_EVENT_STATISTIC_REQUEST,
  payload: inRequest,
});

/**
 *  Выполняет запрос на получение список статистики событии/Events Statistic.
 */

export const getEventStatisticActions = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setEventStatisticRequest(true));
    try {
      const { status, data } = await interceptor.get("/events/statistic");

      if (status === 200) {
        dispatch(setEventStatistic(data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setEventStatisticRequest(false));
  };
};

/**
 * Задает состояние запроса добавляемого события/ Add Events
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetAddEventRequest}
 */

const setAddEventRequest = (inRequest: boolean): ISetAddEventRequest => ({
  type: EventsActionTypes.SET_ADD_EVENT_REQUEST,
  payload: inRequest,
});

/**
 *  Выполняет запрос на добавление события/ Add Events.
 */

export const setAddEventActions = (
  alertName: string,
  targetAsset: string,
  callback: () => void
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setAddEventRequest(true));
    try {
      const { status } = await interceptor.post("/events", {
        alertName: alertName,
        targetAsset: targetAsset,
      });

      if (status === 201) {
        callback();
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setAddEventRequest(false));
  };
};
