import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/styles";

/**
 * Стили компонента Vehicle network map
 */

export const CustomizedGrid = styled(Grid)({
  width: "100%",
  height: 744,
  border: "1px solid rgba(224, 224, 224, 0.1)",
  overflow: "hidden",
});

export const CustomizedImageWrap = styled(Paper)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "100%",
  width: "100%",
  height: "100%",
  padding: "0 30px 0 0",

  "& img": {
    maxWidth: "95%",
    height: "auto",
    display: "block",
    objectFit: "scale-down",
  },
});
