/**
 * Типы ключей объекта Detectors
 */

export interface IDetectorsProps {
  id: number;
  is_active: true;
  alert_name: string;
  alert_description: string | null;
  developer: string;
  impact_rate: string;
  last_updated: string;
  trigger: string;
}

/**
 * Типы ключей объекта Detector
 */

type vehicleModels = {
  brand: string;
  model: string[];
};

type logicRules = {
  name: string;
  state: string;
  value: string;
};

export interface IDetectorProps {
  isActive: true;
  alertName: string;
  alertDescription: string | null;
  developer: string;
  impactRate: string;
  lastUpdated: string;
  trigger: string;
  detectionPeriod: null;
  detectionThreshold: null;
  vehicleModels: vehicleModels[];
  logicRules: logicRules[];
}

/**
 * Типы для экшена и редьюсера Detectors
 */

export enum DetectorsActionTypes {
  GET_DETECTORS_SUCCESS = "GET_DETECTORS_SUCCESS",
  GET_DETECTORS_REQUEST = "GET_DETECTORS_REQUEST",
  GET_DETECTOR_SUCCESS = "GET_DETECTOR_SUCCESS",
  GET_DETECTOR_REQUEST = "GET_DETECTOR_REQUEST",
}

export interface ISetDetectors {
  type: DetectorsActionTypes.GET_DETECTORS_SUCCESS;
  payload: IDetectorsProps[];
}

export interface ISetDetectorsRequest {
  type: DetectorsActionTypes.GET_DETECTORS_REQUEST;
  payload: boolean;
}

export interface ISetDetector {
  type: DetectorsActionTypes.GET_DETECTOR_SUCCESS;
  payload: IDetectorProps;
}

export interface ISetDetectorRequest {
  type: DetectorsActionTypes.GET_DETECTOR_REQUEST;
  payload: boolean;
}

export type IActionDetectors =
  | ISetDetectors
  | ISetDetectorsRequest
  | ISetDetector
  | ISetDetectorRequest;
