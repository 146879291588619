import { AuthUserActionTypes, IUserAction } from "../../types";

interface IInitialState {
  token: string | null;
  loginRequest: boolean;
}

/**
 * User редьюсер
 */

const initialState = {
  token: null,
  loginRequest: false,
};

export const userReducer = (
  state = initialState,
  action: IUserAction
): IInitialState => {
  switch (action.type) {
    case AuthUserActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case AuthUserActionTypes.SET_LOGIN_REQUEST:
      return {
        ...state,
        loginRequest: action.payload,
      };
    default:
      return state;
  }
};
