import { Box, Button, styled, Typography } from "@mui/material";

export const Wrapper = styled(Box)(
  () => `
  background: radial-gradient(50% 50% at 50% 50%, #262524 0%, #181715 52.08%, #040404 100%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  margin: auto;
`
);

export const Container = styled(Box)(
  () => `
  border: 1px solid #2f2e2d;
  width: 500px;
  height: 630px;
  display: flex;
  flex-direction: column;
`
);

export const Content = styled(Box)(
  () => `
  height: inherit;
  padding: 40px 32px 65px;
  display: flex;
  flex-direction: column;
`
);

export const Title = styled(Typography)(
  () => `
  margin-bottom: 12px;

  span {
    color: #FF9800;
  }
`
);

export const Description = styled(Typography)(
  () => `
  color: #898989;
  margin-bottom: 60px;
`
);

export const SendButton = styled(Button)(
  () => `
  margin-top: auto;
  height: 60px;
`
);
