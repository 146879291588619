import { combineReducers } from "redux";

import { alertsReducer } from "./_alerts.reducer";
import { detectorsReducer } from "./_detectors.reducer";
import { eventsReducer } from "./_events.reducer";
import { settingsReducer } from "./_settings.reducer";
import { userReducer } from "./_user.reducer";
import { vehiclesReducer } from "./_vehicles.reducer";

export const rootReducer = combineReducers({
  vehicles: vehiclesReducer,
  detectors: detectorsReducer,
  user: userReducer,
  alerts: alertsReducer,
  events: eventsReducer,
  settings: settingsReducer,
});

export type RootStateTypes = ReturnType<typeof rootReducer>;
