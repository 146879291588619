import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

// Todo Yusuf: описание
export const Setting: React.FC = () => {
  return (
    <Grid p="40px 20px">
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        sx={{ width: "100%", "& label": { m: "0 0 15px 5px" } }}
      >
        <FormGroup sx={{ width: "33%" }}>
          <Typography variant="body1" color="textSecondary" mb="20px">
            Hunter
          </Typography>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Разрешить  доступ к конкретному меню"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Разрешить квитировать alert
"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Разрешить  добавления/редактирование БД сигналов от авто"
          />
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Разрешить  скачивание/отправки отчетов по почте"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Разрешить  изменения настроек в СИЕМ"
          />
        </FormGroup>
        <FormGroup sx={{ width: "33%" }}>
          <Typography variant="body1" color="textSecondary" mb="20px">
            Analyst
          </Typography>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Разрешить  доступ к конкретному меню"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Разрешить квитировать alert
"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Разрешить  добавления/редактирование БД сигналов от авто"
          />
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Разрешить  скачивание/отправки отчетов по почте"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Разрешить  изменения настроек в СИЕМ"
          />
        </FormGroup>
        <FormGroup sx={{ width: "33%" }}>
          <Typography variant="body1" color="textSecondary" mb="20px">
            Researcher
          </Typography>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Разрешить  доступ к конкретному меню"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Разрешить квитировать alert
"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Разрешить  добавления/редактирование БД сигналов от авто"
          />
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Разрешить  скачивание/отправки отчетов по почте"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Разрешить  изменения настроек в СИЕМ"
          />
        </FormGroup>
      </Stack>
      <Box m="300px 0 0 0">
        <Button
          variant="contained"
          size="large"
          sx={{ width: "160px", marginRight: "20px" }}
        >
          Save
        </Button>
        <Button variant="outlined" size="large" sx={{ width: "160px" }}>
          Cancel
        </Button>
      </Box>
    </Grid>
  );
};
