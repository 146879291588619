import { Stack } from "@mui/material";
import { styled } from "@mui/styles";

export const CustomStack = styled(Stack)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "end",
  marginTop: 10,
  position: "relative",
  overflow: "hidden",

  "& span:first-child": {
    position: "absolute",
    left: -25,
    transform: "rotate(-90deg)",
  },

  "& label": {
    position: "absolute",
    width: 40,
    left: 8,
    bottom: 16,
  },
});
