import React from "react";

import { IIconNavigationBar } from "../../../types";
import { getIconStyle } from "../../../utils";

/**
 * Иконка детектора. Принимает на вход:
 *
 * @param {string} className - Внешний класс для стилизации
 * @param {string} height - Высота
 * @param {string} width - Ширина
 * @param {string} fill - Цвет
 * @param {boolean} isDark - Стиль иконки темный/яркий
 * @returns {JSX.Element}
 */

export const Detector: React.FC<IIconNavigationBar> = ({
  fill = "white",
  width = "36",
  height = "36",
  className = "",
  isDark,
}) => {
  const iconStyle = getIconStyle(isDark);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        opacity="0.05"
        d="M29.25 36H6.75C3.02175 36 0 32.9782 0 29.25V6.75C0 3.02175 3.02175 0 6.75 0H29.25C32.9782 0 36 3.02175 36 6.75V29.25C36 32.9782 32.9782 36 29.25 36Z"
        fill={iconStyle.white}
      />
      <path
        d="M17.3085 14.2943C14.6625 14.634 12.75 17.0157 12.75 19.683V21.7497H23.2507V19.4997C23.2507 16.3778 20.5103 13.8825 17.3085 14.2943ZM18.0004 17.2497C16.7595 17.2497 15.7504 18.2588 15.7504 19.4997C15.7504 19.9148 15.4151 20.25 15 20.25C14.5849 20.25 14.2496 19.9137 14.2496 19.4997C14.2508 17.4319 15.9326 15.75 18.0004 15.75C18.4155 15.75 18.7507 16.0864 18.7507 16.5004C18.7507 16.9144 18.4155 17.2497 18.0004 17.2497Z"
        fill={iconStyle.orange}
      />
      <path
        d="M18.0004 12.7496C17.5852 12.7496 17.25 12.4133 17.25 11.9993V9.74925C17.25 9.33525 17.5852 9 18.0004 9C18.4155 9 18.7507 9.33637 18.7507 9.75037V12.0004C18.7507 12.4144 18.4155 12.7496 18.0004 12.7496Z"
        fill={iconStyle.orange}
      />
      <path
        d="M13.5002 14.2504C13.3078 14.2504 13.1165 14.1773 12.9703 14.031L11.3795 12.4403C11.087 12.1478 11.087 11.673 11.3795 11.3794C11.672 11.0869 12.1468 11.0869 12.4404 11.3794L14.0312 12.9702C14.3237 13.2627 14.3237 13.7374 14.0312 14.031C13.8838 14.1773 13.6914 14.2504 13.5002 14.2504Z"
        fill={iconStyle.orange}
      />
      <path
        d="M22.4993 14.2504C22.3069 14.2504 22.1156 14.1773 21.9694 14.031C21.6769 13.7385 21.6769 13.2638 21.9694 12.9702L23.5601 11.3794C23.8526 11.0869 24.3274 11.0869 24.621 11.3794C24.9135 11.6719 24.9135 12.1467 24.621 12.4403L23.0303 14.031C22.8829 14.1773 22.6916 14.2504 22.4993 14.2504Z"
        fill={iconStyle.orange}
      />
      <path
        d="M10.4996 18.7504H9.74925C9.33525 18.7504 9 18.414 9 18C9 17.586 9.33525 17.2496 9.75037 17.2496H10.5007C10.9147 17.2496 11.25 17.586 11.25 18C11.25 18.414 10.9148 18.7504 10.4996 18.7504Z"
        fill={iconStyle.orange}
      />
      <path
        d="M26.2511 18.7504H25.3129C24.8977 18.7504 24.5625 18.414 24.5625 18C24.5625 17.586 24.8977 17.2496 25.3129 17.2496H26.25C26.6651 17.2496 27.0004 17.586 27.0004 18C27.0004 18.414 26.6651 18.7504 26.2511 18.7504Z"
        fill={iconStyle.orange}
      />
      <path
        d="M25.6882 27H10.3118C9.58838 27 9 26.4116 9 25.6871V24.5621C9 23.8388 9.58837 23.2493 10.3129 23.2493H25.6882C26.4116 23.2493 27.0011 23.8376 27.0011 24.5621V25.6871C27 26.4116 26.4116 27 25.6882 27Z"
        fill={iconStyle.white}
      />
    </svg>
  );
};
