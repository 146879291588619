/**
 * Типы ключей объекта Alerts
 */

export interface IAlertsProps {
  id: number;
  type: string;
  dateCreated: string;
  dateUpdated: string;
  detectorId: number;
  alertName: string;
  alertDescription: string | null;
  vehicleVin: string;
  vehicleBrand: string;
  vehicleModel: string;
  targetAssets: string[];
  impactRate: string;
  isChecked: boolean;
  isNoticed: boolean;
}

export interface IAlertProps {
  dateCreated: string;
  detectorId: number;
  alertName: string;
  alertDescription: boolean;
  vehicleVin: string;
  vehicleId: number;
  targetAssets: string[];
  impactRate: string;
  isNoticed: boolean;
}

/**
 * Типы ключей объекта Alerts Statistic
 */

export interface IAlertsStatistic {
  category: string;
  value: number;
}

/**
 * Типы ключей объекта Alerts Timeline
 */

export interface IAlertsTimeline {
  time: string;
  severe: number;
  major: number;
  moderate: number;
  negligible: number;
  noImpact: number;
  sum: number;
}

/**
 * Типы ключей объекта Target Assets
 */

export type ITargetAssets = string[];

/**
 * Типы для экшена и редьюсера Alerts
 */

export enum AlertsActionTypes {
  SET_ALERT_SUCCESS = "SET_ALERT_SUCCESS",
  SET_ALERT_REQUEST = "SET_ALERT_REQUEST",
  SET_ALERTS_SUCCESS = "SET_ALERTS_SUCCESS",
  SET_ALERTS_REQUEST = "SET_ALERTS_REQUEST",
  SET_ALERTS_STATISTIC_SUCCESS = "SET_ALERTS_STATISTIC_SUCCESS",
  SET_ALERTS_STATISTIC_REQUEST = "SET_ALERTS_STATISTIC_REQUEST",
  SET_ALERTS_TIMELINE_SUCCESS = "SET_ALERTS_TIMELINE_SUCCESS",
  SET_ALERTS_TIMELINE_REQUEST = "SET_ALERTS_TIMELINE_REQUEST",
  SET_TARGET_ASSETS_SUCCESS = "SET_TARGET_ASSETS_SUCCESS",
  SET_TARGET_ASSETS_REQUEST = "SET_TARGET_ASSETS_REQUEST",
}

export interface ISetAlerts {
  type: AlertsActionTypes.SET_ALERTS_SUCCESS;
  payload: IAlertsProps[];
}

export interface ISetAlertsRequest {
  type: AlertsActionTypes.SET_ALERTS_REQUEST;
  payload: boolean;
}

export interface ISetAlert {
  type: AlertsActionTypes.SET_ALERT_SUCCESS;
  payload: IAlertProps;
}

export interface ISetAlertRequest {
  type: AlertsActionTypes.SET_ALERT_REQUEST;
  payload: boolean;
}

export interface ISetAlertsStatistic {
  type: AlertsActionTypes.SET_ALERTS_STATISTIC_SUCCESS;
  payload: IAlertsStatistic[];
}

export interface ISetAlertsStatisticRequest {
  type: AlertsActionTypes.SET_ALERTS_STATISTIC_REQUEST;
  payload: boolean;
}

export interface ISetAlertsTimeline {
  type: AlertsActionTypes.SET_ALERTS_TIMELINE_SUCCESS;
  payload: IAlertsTimeline[];
}

export interface ISetAlertsTimelineRequest {
  type: AlertsActionTypes.SET_ALERTS_TIMELINE_REQUEST;
  payload: boolean;
}

export interface ISetTargetAssets {
  type: AlertsActionTypes.SET_TARGET_ASSETS_SUCCESS;
  payload: ITargetAssets;
}

export interface ISetTargetAssetsRequest {
  type: AlertsActionTypes.SET_TARGET_ASSETS_REQUEST;
  payload: boolean;
}

export type IActionAlerts =
  | ISetAlerts
  | ISetAlertsRequest
  | ISetAlertsStatistic
  | ISetAlertsStatisticRequest
  | ISetAlertsTimeline
  | ISetAlertsTimelineRequest
  | ISetAlert
  | ISetAlertRequest
  | ISetTargetAssets
  | ISetTargetAssetsRequest;
